import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

//styles
import ViewRecordStyles from './ViewRecordsStyles';

//helpers
import { debounce, toCapitalizedWords } from 'src/shared/utils';

//constants
import { ICON_SEARCH } from 'src/shared/constants/icons';
import { CustomInput } from 'src/widgets';
import { toast } from 'react-toastify';
import { useLazyGetLeadsQuery } from 'src/redux/queries/Admin/Leads';
import { IContacts } from 'src/defs';
import { MagnifyingGlass } from 'react-loader-spinner';

interface IProps {
    recordType: string;
    handleClose: VoidFunction;
}

const ViewRecords = ({ recordType, handleClose }: IProps) => {
    const [getLeads] = useLazyGetLeadsQuery();

    const [query, setQuery] = useState('');
    const [contactsQuery, setContactsQuery] = useState({
        limit: 1000,
        offset: 0,
        sortBy: 'updatedAt',
        order: -1,
        searchQuery: ''
    });
    const [contacts, setContacts] = useState<IContacts[]>([
        {
            id: '6723e3e07abb10f5ac54154d',
            userId: '107002996423245594163',
            email: '',
            annualRevenue: '$250 million (2022) based on latest financial report',
            businessOutcomes: '',
            commonObjection: '',
            companyAddress: 'Santa Clara, California, USA',
            companyName: 'Gigamon',
            companySize: '501-1000 employees',
            competitiveCoverage:
                'Strong foothold in North America with growing presence in EMEA and APAC regions.',
            competitorTracking:
                'Competes with companies like Cisco and Arista Networks in network visibility solutions.',
            country: 'United States',
            createdAt: '2024-10-31T19:59:01.073Z',
            departmentFunction: 'Operations',
            enrichedIndustryData:
                'Data centers are under pressure to reduce their environmental impact while managing increasing data loads.',
            executiveInitiatives:
                'Developing partnerships with cloud service providers to enhance product capabilities.',
            firstName: '',
            frustrationsAndChallenges: '',
            growthInitiatives:
                'Expanding product offerings to include more comprehensive observability solutions across hybrid environments.',
            industry: 'Technology',
            industryUseCaseFit:
                "Gigamon's solutions help data centers optimize energy use while maintaining high performance levels.",
            jobResponsibilities: '',
            jobTitle: '',
            keyPriorities:
                'Reducing energy consumption in data centers, enhancing network security, improving tool efficiency.',
            kloutScore: '60',
            kpis: 'Reduction in energy consumption, improved network security, increased operational efficiency.',
            lastActivityDate: '2023-06-20',
            lastEmailClickDate: '2023-07-01',
            lastName: '',
            status: 'Cold Segment',
            offerings: 'Network visibility solutions, energy-efficient traffic management tools.',
            orgId: '33',
            painPoints:
                'High energy costs and regulatory pressures to reduce carbon emissions in data centers.',
            personInsight: '',
            personalMotivators: '',
            productBenefitsAdoption:
                'Adopted energy-saving techniques such as deduplication and flow mapping to reduce power usage.',
            purchaseTriggers: '',
            seniorityLevel: '',
            solutionProviders:
                'Collaborates with major cloud providers to offer integrated solutions.',
            sourceId: '',
            subIndustry: 'Data Center Solutions',
            successMeasures: '',
            targetingStrategy1:
                "Promote Gigamon's energy-saving capabilities to data centers looking to cut operational costs.",
            targetingStrategy2:
                'Position Gigamon as a leader in network visibility that enhances both security and sustainability.',
            technographicDetails:
                'Specializes in network visibility and traffic optimization technologies.',
            technologyAdoptionStage: 'Fully operational',
            technologyPlatform: 'Deep Observability Pipeline, Network Traffic Analysis Tools',
            updatedAt: '2024-10-31T20:09:45.218Z',
            useCaseFit:
                'Energy-efficient solutions fit well with data center operations aiming to reduce carbon footprint.',
            userEmail: 'vijayaraghavanjayanth@gmail.com'
        } as unknown as IContacts
    ]);
    const [totalContacts, setTotalContacts] = useState<number>(1);
    const [isloading, setIsLoading] = useState(false);

    useEffect(() => {
        // fetchContacts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactsQuery]);

    const fetchContacts = async () => {
        const { offset, limit, sortBy, order, searchQuery } = contactsQuery;
        try {
            setIsLoading(true);
            await getLeads({
                offset,
                limit,
                searchQuery,
                recordType
            })
                .unwrap()
                .then((data: any) => {
                    if (data.isGrouped) {
                        setContacts(data?.contacts || []);
                    } else {
                        setContacts(data?.contacts || []);
                    }
                    setTotalContacts(data.total);
                    // setTotalPages(Math.ceil(data.total / contactsQuery.limit) || 1);
                })
                .catch((e: any) => {
                    throw e;
                });
        } catch (error) {
            console.log('error >>>> ', error);
        } finally {
            setIsLoading(false);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearch = useCallback(
        debounce(async (query) => {
            try {
                if (query && query?.length <= 3) return;

                setContactsQuery((state) => ({
                    ...state,
                    searchQuery: query
                }));
            } catch (e: any) {
                console.log(e);
                toast.error(e, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }, 1000),
        []
    );

    const handleSearch = (e: { target: { value: string } }) => {
        const value = e.target.value;
        setQuery(value);
        debounceSearch(value);
    };

    const renderLoader = () => {
        return (
            <div className={cx('p-4', 'flex justify-between items-center')}>
                <MagnifyingGlass
                    height={48}
                    width={48}
                    color="#000000"
                    glassColor="#ffffff"
                    ariaLabel="loading"
                    visible={isloading}
                    wrapperClass={cx('w-full')}
                />
            </div>
        );
    };

    const HEADINGS = ['First Name', 'Last Name', 'Email'];

    const renderData = () => {
        return (
            <div className={cx('border-b-2 px-6 py-4')}>
                <div className={cx('w-[90%] overflow-scroll')}>
                    <div className={cx('flex items-center gap-2','bg-slate-200')}>
                        {HEADINGS.map((heading, key) => (
                            <div key={key}>{heading}</div>
                        ))}
                    </div>
                    {contacts.map((contact, key) => {
                        return (
                            <div key={key} className={cx('flex items-center gap-2')}>
                                {Object.values(contact).map((value, idx) => (
                                    <div key={idx}>{value}</div>
                                ))}
                            </div>
                        );
                    })}
                </div>
                <div className={cx('text-end')}>{totalContacts}</div>
            </div>
        );
    };

    return (
        <div className={cx(ViewRecordStyles.overlay)}>
            <div className={cx(ViewRecordStyles.container)}>
                <div className={cx('flex items-center justify-between', 'border-b-2 px-6 py-4')}>
                    <div
                        className={cx('text-lg font-semibold')}
                    >{`Incomplete Records - ${toCapitalizedWords(recordType)}`}</div>
                    <div className={cx(ViewRecordStyles.close)} onClick={handleClose}>
                        <div className={cx(ViewRecordStyles.closeIcon)}>X</div>
                    </div>
                </div>

                <div className={cx('px-6 py-4')}>
                    <CustomInput
                        className={cx(`text-black !w-[280px]`)}
                        inputClass={cx('h-[36px]')}
                        value={query}
                        onChange={handleSearch}
                        inputProps={{ placeholder: 'Search Records...' }}
                        icon={ICON_SEARCH}
                    />
                </div>

                {isloading ? renderLoader() : renderData()}
            </div>
        </div>
    );
};

export default React.memo(ViewRecords);
