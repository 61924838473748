//libs
import React from 'react';
import cx from 'classnames';

//widgets
import { Checkbox, CustomButton } from 'src/widgets';

//styles
import styles from './PersonaCard.module.scss';

//constants
import { darkContainer } from 'src/shared/styles/colors';

interface IProps {
    data: any[];
    audiences: [];
    isDark?: boolean;
    handleEditPersonaCard: (audiencePersona: any) => void;
    handleSelectAudience: (audiencePersona: any) => void;
}

const PersonaCard = ({
    data,
    audiences,
    isDark,
    handleEditPersonaCard,
    handleSelectAudience
}: IProps) => {
    const renderFooter = (card: {
        useCases: any;
        personInsight: any;
        purchaseTriggers: any;
        dimensions: any;
    }) => {
        if (!card.useCases && !card.personInsight && !card.purchaseTriggers && !card.dimensions)
            return false;

        return true;
    };

    const Row = ({ title, value }: { title: string; value: string }) => {
        if (!value) return null;

        return (
            <div className={cx('flex items-start', 'my-1')}>
                <div className={cx(styles.cardBodyLeftrow)}>{title}</div>
                <div className={cx(styles.cardBodyRightrow, 'font-semibold')}>
                    {trimString(value)}
                </div>
            </div>
        );
    };

    return (
        <div className={cx(isDark && darkContainer)}>
            <div className={cx('py-2 px-2')}>
                <h1 className={cx('text-2xl font-semibold')}>{'Get Started'}</h1>
            </div>
            <div
                className={cx(
                    'rounded-lg shadow',
                    'my-2 p-4',
                    isDark ? 'bg-slate-800' : 'bg-white'
                )}
            >
                <div className={cx('flex items-center justify-between')}>
                    <h1 className={cx('text-xl font-semibold')}>{'1. Audience Persona Card(s)'}</h1>
                    <CustomButton title="Add New" onClick={() => handleEditPersonaCard({})} />
                </div>

                <div className={cx(styles.cardContainer)}>
                    {data.map((card, key) => (
                        <div
                            key={key}
                            className={cx(
                                'm-2 p-4',
                                'bg-slate-200',
                                'rounded-lg',
                                'shadow-lg',
                                styles.card,
                                isDark && darkContainer
                            )}
                        >
                            <div className={cx(styles.edit)}>
                                <Checkbox
                                    isChecked={audiences.find((a: any) => a._id === card._id)}
                                    handleCheck={() => handleSelectAudience(card)}
                                />
                            </div>
                            <div className={cx(styles.header, 'flex items-center')}>
                                <div
                                    className={cx(
                                        'flex-1',
                                        'bg-slate-300',
                                        'p-2 mr-3',
                                        'rounded',
                                        isDark && 'bg-slate-800'
                                    )}
                                >
                                    <div className="">{card.name}</div>
                                    <div className="">{card.title}</div>
                                </div>
                                <div className={cx('w-40 text-center', 'text-2xl')}>
                                    {card.companyName}
                                </div>
                            </div>

                            <div
                                className={cx(
                                    // 'flex items-center justify-between flex-wrap',
                                    'p-2 my-4',
                                    'rounded-lg',
                                    styles.cardBody,
                                    isDark ? 'bg-slate-800' : 'bg-white'
                                )}
                            >
                                <Row title={'Segment'} value={card.segment} />
                                <Row title={'Industry'} value={card.industry} />
                                <Row
                                    title={'Performance Indicators'}
                                    value={card.kpis || card.personalityIndicators}
                                />
                                <Row title={'Offerings'} value={card.offerings} />
                                <Row
                                    title={'Personal Motivators'}
                                    value={card.personalMotivators}
                                />
                                <Row title={'Business Outcomes'} value={card.businessOutcomes} />
                                <Row title={'Pain Points'} value={card.frustrationsAndChallenges} />
                                <Row title={'Success Measures'} value={card.successMeasures} />
                                <Row title={'Common Objections'} value={card.commonObjection} />
                            </div>

                            {renderFooter(card) && (
                                <div className={cx('p-2 my-4', 'bg-white rounded-lg')}>
                                    <div className={cx('my-1')}>
                                        <div className={cx('font-semibold')}>Use Cases</div>
                                        <div className={styles.cardFooterData}>
                                            {trimString(card.useCases)}
                                        </div>
                                    </div>
                                    <div className={cx('my-1')}>
                                        <div className={cx('font-semibold')}>Triggers</div>
                                        <div className={styles.cardFooterData}>
                                            {trimString(card.purchaseTriggers)}
                                        </div>
                                    </div>
                                    <div className={cx('my-1')}>
                                        <div className={cx('font-semibold')}>Insights</div>
                                        <div className={styles.cardFooterData}>
                                            {trimString(card.personInsight)}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className={cx('flex justify-between')}>
                                <CustomButton
                                    title={'Edit'}
                                    onClick={() => handleEditPersonaCard(card)}
                                    className={cx('p-0 h-8')}
                                />

                                <CustomButton
                                    title={'Clone'}
                                    onClick={() =>
                                        handleEditPersonaCard({
                                            ...card,
                                            _id: '',
                                            isClone: true,
                                            name: `${card.name} Clone`
                                        })
                                    }
                                    className={cx('p-0 h-8')}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default React.memo(PersonaCard);

const trimString = (value: string) => {
    if (value?.length >= 100) {
        return `${value.slice(0, 100)}...`;
    }

    return value;
};
