//libs
import React, { useRef } from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

//widgets
import Tooltip from 'src/components/Tooltip';
import { Checkbox } from 'src/widgets';

const CheckTitle = ({
    heading = '',
    headingTooltip = '',
    data = [],
    error = '',
    onSelect,
    checked = []
}) => {
    const tooltipRef = useRef(null);

    const handleSelect = (item) => {
        onSelect(item.value);
    };

    const checkedClass = (item) =>
        cx(styles.containerContentBox, {
            [styles.containerContentBoxChecked]: checked?.some((f) => f.type === item.value)
        }, 'text-white');

    return (
        <div className={styles.container}>
            <div className={styles.containerTitle}>
                <Tooltip
                    className={styles.containerTitleText}
                    title={heading}
                    tooltipText={headingTooltip}
                    tooltipRef={tooltipRef}
                />
                <span className={styles.containerTitleError}>{error}</span>
            </div>
            <div className={styles.containerContent}>
                {data.map((item, key) => (
                    <Checkbox
                        className={checkedClass(item)}
                        key={key}
                        data={item}
                        id={`${heading}_${key}`}
                        handleCheck={() => handleSelect(item)}
                        isChecked={checked?.some((f) => f.type === item.value)}
                    />
                ))}
            </div>
        </div>
    );
};

export default React.memo(CheckTitle);
