import React, { useRef, useState } from 'react';
import cx from 'classnames';

//assets
import Icomoon from 'src/assets/Icomoon';

//analytics
import { ANALYTICS, analytics } from 'src/shared/utils/Analytics';

//helpers
import { highlightDifferences } from 'src/shared/utils/helpers';
import { formatMarkdown } from 'src/shared/utils';

//widgets
import { CustomButton, CustomTextArea } from 'src/widgets';
import { Zoom, toast } from 'react-toastify';
import ResultContentTags from '../ResultContentTags';
import Feedback from '../Feedback';
import ShareContent from 'src/components/ShareContent';

//styles
import styles from './styles.module.scss';

//constants
import { LABELS } from 'src/shared/strings';

//defs
import { IAudience, IFeedback, IMaxWordLimit } from 'src/defs';
import { IRegeneratedChoice } from '../../ResultTab';

interface IProps {
    choice: { choices: string[]; type: string };
    targetKey: number;
    pos: number;
    audience: IAudience;
    isDark?: boolean;
    cancelEdit: VoidFunction;
    saveData: VoidFunction;
    updateResult: (
        value: string,
        targetKey: number,
        pos: number,
        idx: number,
        type: string,
        saveData?: boolean
    ) => void;
    maxWords: IMaxWordLimit;
    handleRangeChange: (e: React.ChangeEvent<HTMLInputElement>, type: string) => void;
    handleRegenerate: (audience: IAudience, targetKey: number, key: number, type: string) => void;
    regeneratedChoice: IRegeneratedChoice;
    subType: string;
    handleFeedbackSubmit: (
        type: string,
        audienceLabel: string,
        isLiked: boolean,
        comment: string,
        key: number
    ) => void;
    feedback: IFeedback[];
}

const BdrCadenceResult = ({
    choice,
    targetKey,
    pos,
    audience,
    isDark,
    cancelEdit,
    saveData,
    updateResult,
    maxWords,
    handleRangeChange,
    handleRegenerate,
    regeneratedChoice,
    subType,
    handleFeedbackSubmit,
    feedback
}: IProps) => {
    const [editPosition, setEditPosition] = useState<number[]>(() =>
        choice.choices.map((_, idx) => idx)
    );
    const textref: React.RefObject<HTMLInputElement>[] = [
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null)
    ];

    const renderSendMail = (data: string) => {
        return <ShareContent data={data} type={choice.type} />;
    };

    const emptyEditPosition = (idx: number) => {
        let positions = editPosition;
        let pos = positions.indexOf(idx);
        positions.splice(pos, 1);
        setEditPosition(positions);
    };

    const renderIcons = (idx: number, isRegenerated: boolean) => {
        if (editPosition.includes(idx)) {
            return (
                <div className={styles.icons}>
                    <Icomoon
                        className={styles.iconsCancel}
                        icon={'cancel'}
                        onClick={() => {
                            emptyEditPosition(idx);
                            cancelEdit();
                        }}
                    />

                    <Icomoon
                        className={styles.iconsAccept}
                        icon={'accept'}
                        onClick={() => {
                            emptyEditPosition(idx);
                            saveData();
                        }}
                    />
                </div>
            );
        }

        return (
            <Icomoon
                className={styles.iconsPencil}
                icon={'pencil'}
                onClick={() => {
                    if (isRegenerated) {
                        toast.info('Please Accept one choice to start editing', {
                            toastId: 1,
                            transition: Zoom
                        });
                        return;
                    }
                    setEditPosition((state) => [...state, idx]);
                    analytics.sendClickEvent(
                        ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
                        ANALYTICS.ACTIONS.EDIT_CHOICE
                    );
                }}
            />
        );
    };

    const renderTextBox = (
        content: string,
        targetKey: number,
        pos: number,
        idx: number,
        type: string
    ) => {
        return (
            <CustomTextArea
                value={content}
                showCount={false}
                onChange={(value: any) => updateResult(value, targetKey, pos, idx, type)}
            />
        );
    };

    const renderRegenerateCta = (key: number, type: string, isEditing: boolean) => {
        const contentFeedback =
            feedback?.find((feed) => feed.contentType === type && feed.position === key) || null;
        return (
            <div
                className={cx(styles.containerContentAction, isDark && 'bg-slate-800 py-2 rounded')}
            >
                <label htmlFor={`${choice.type}maxWords`}>
                    &asymp; Words : {maxWords[choice.type]}
                </label>
                <input
                    id={`${choice.type}maxWords`}
                    type={'range'}
                    value={maxWords[choice.type]}
                    step={10}
                    min={10}
                    max={800}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleRangeChange(e, choice?.type)
                    }
                    className={styles.wordRange}
                />
                <CustomButton
                    gradient
                    title={LABELS.REGENERATE}
                    onClick={() => {
                        handleRegenerate(audience, targetKey, key, choice.type);
                    }}
                />
                <Feedback
                    contentFeedback={contentFeedback}
                    handleFeedbackSubmit={(isLiked, comment) =>
                        handleFeedbackSubmit(choice.type, audience?.label, isLiked, comment, key)
                    }
                />
            </div>
        );
    };

    const acceptChoiceCta = (
        value: string,
        targetKey: number,
        pos: number,
        idx: number,
        type: string
    ) => {
        return (
            <CustomButton
                gradient
                title={LABELS.ACCEPT}
                onClick={() => {
                    updateResult(value, targetKey, pos, idx, type, true);
                }}
            />
        );
    };

    const renderList = (
        list: string[],
        targetKey: number,
        pos: number,
        type: string,
        regeneratedList = ['']
    ) => {
        let str1, str2;
        return (
            <div className={styles.list}>
                {list?.map((item: string, idx: number) => {
                    let regeneratedString: string = regeneratedList[idx];
                    str1 = formatMarkdown(item);
                    str2 = regeneratedString;
                    regeneratedString = highlightDifferences(str1, regeneratedString, styles.diff);
                    return (
                        <div
                            className={styles.listItem}
                            key={idx}
                            style={{ minHeight: textref[idx]?.current?.offsetHeight }}
                        >
                            <div className="flex-1">
                                <ResultContentTags idx={idx} subType={subType} />
                                {editPosition.includes(idx) ? (
                                    <div className={styles.listItemContainer}>
                                        <div className={styles.listItemContainerWrap}>
                                            {renderTextBox(str1, targetKey, pos, idx, type)}
                                            {!!regeneratedString && (
                                                <div className={styles.listItemContainerCta}>
                                                    {acceptChoiceCta(
                                                        str1,
                                                        targetKey,
                                                        pos,
                                                        idx,
                                                        type
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        {!!regeneratedString && (
                                            <div className={styles.listItemContainerWrap}>
                                                <div
                                                    className={styles.listItemContainerText}
                                                    ref={textref[idx]}
                                                    dangerouslySetInnerHTML={{
                                                        __html: formatMarkdown(regeneratedString)
                                                    }}
                                                />
                                                <div className={styles.listItemContainerCta}>
                                                    {acceptChoiceCta(
                                                        str2,
                                                        targetKey,
                                                        pos,
                                                        idx,
                                                        type
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className={styles.listItemContainer}>
                                        <div className={styles.listItemContainerWrap}>
                                            <div
                                                className={styles.listItemContainerText}
                                                ref={textref[idx]}
                                                dangerouslySetInnerHTML={{
                                                    __html: str1?.replaceAll('\n', '<br />')
                                                }}
                                            />
                                            {!!regeneratedString && (
                                                <div className={styles.listItemContainerCta}>
                                                    {acceptChoiceCta(
                                                        str1,
                                                        targetKey,
                                                        pos,
                                                        idx,
                                                        type
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        {!!regeneratedString && (
                                            <div className={styles.listItemContainerWrap}>
                                                <div
                                                    className={styles.listItemContainerText}
                                                    ref={textref[idx]}
                                                    dangerouslySetInnerHTML={{
                                                        __html: regeneratedString.replaceAll(
                                                            '\n',
                                                            '<br />'
                                                        )
                                                    }}
                                                />
                                                <div className={styles.listItemContainerCta}>
                                                    {acceptChoiceCta(
                                                        str2,
                                                        targetKey,
                                                        pos,
                                                        idx,
                                                        type
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className={styles.listItemIcons}>
                                {/* {renderIcons(idx, !!regeneratedString)} */}
                                {renderSendMail(str1)}
                            </div>
                            {renderRegenerateCta(idx, type, editPosition.includes(idx))}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.wrapperTitle}>{'BDR Cadence'}</h1>
            {renderList(choice?.choices, targetKey, pos, choice?.type, regeneratedChoice?.choices)}
        </div>
    );
};

export default React.memo(BdrCadenceResult);
