import React, { useEffect, useState } from 'react';
import cx from 'classnames';

//styles
import styles from './AddAudience.module.scss';

//widgets
import { CustomInput } from 'src/widgets';
import Icomoon from 'src/assets/Icomoon';

//constants
import { AUDIENCE, LABELS, STRINGS } from 'src/shared/strings';
import { AUDIENCE_INDUSTRY_OPTIONS, AUDIENCE_ROLE_OPTIONS } from 'src/shared/constants';

//helpers
import { useDispatch, useSelector } from 'react-redux';
import { startLoading, stopLoading } from 'src/redux/reducers';
import { validateForm } from './validateForm';
import { toast } from 'react-toastify';
import { addToOptions } from 'src/shared/utils';

//api
import {
    useAddAudienceMutation,
    useLazyGetAudienceSegmentsQuery,
    useUpdateAudienceMutation
} from 'src/redux/queries/Audience';
import { updateSegments } from 'src/redux/reducers/taxonomy';

const defaultState = {
    segment: '',
    targetIndustry: '',
    title: '',
    companyProfile: '',
    linkedinProfile: '',
    kpis: '',
    offerings: '',
    jobResponsibilities: '',
    personalMotivators: '',
    businessOutcomes: '',
    frustrationsAndChallenges: '',
    useCases: '',
    purchaseTriggers: '',
    successMeasures: '',
    commonObjection: '',
    personInsight: ''
};

const AddAudience = ({ isDark, data, isOpen, onClose, onSuccess = (audience) => {}, setData }) => {
    const userDetails = useSelector((state) => state.user.userDetails);
    const taxonomySegments = useSelector((state) => state.taxonomy.segments) || [];

    const dispatch = useDispatch();

    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState(defaultState);
    const [errors, setErrors] = useState(defaultState);
    const [segmentOptions, setSegmentOptions] = useState([]);

    const [addNewAudience] = useAddAudienceMutation();
    const [updateAudience] = useUpdateAudienceMutation();
    const [getAudienceSegments] = useLazyGetAudienceSegmentsQuery();

    useEffect(() => {
        if (taxonomySegments?.length) {
            setSegmentOptions(taxonomySegments);
        } else {
            fetchSegments();
        }
        return () => {
            setSegmentOptions([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data && Object.entries(data)?.length) {
            let kpis = data.kpis || data.personalityIndicators || '';
            let obj = {
                ...data,
                segment: { label: data.segment, value: data.segment },
                targetIndustry: { label: data.industry, value: data.industry },
                title: { label: data.title, value: data.title },
                companyProfile: data.companyName,
                linkedinProfile: data.linkedinUrl,
                kpis: kpis?.split(',')?.map((kpi) => ({ label: kpi, value: kpi })),
                id: data._id
            };
            setIsEditing(true);
            setFormData({ ...obj });
        }
    }, [data, isOpen]);

    const fetchSegments = async () => {
        try {
            await getAudienceSegments()
                .unwrap()
                .then((data) => {
                    if (data?.data?.length) {
                        dispatch(updateSegments(data.data));
                        let options = data?.data?.map((d) => ({ label: d, value: d }));
                        setSegmentOptions(options);
                        return;
                    }
                });
        } catch (err) {
            console.log(err);
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        if (value?.__isNew__) {
            const { __isNew__, ...rest } = value;
            switch (id) {
                case 'role':
                    AUDIENCE_ROLE_OPTIONS.push(rest);
                    break;
                case 'targetIndustry':
                    AUDIENCE_INDUSTRY_OPTIONS.push(rest);
                    break;
                default:
                    break;
            }
        }
        setFormData({ ...formData, [id]: value });
        setErrors({ ...errors, [id]: '' });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            const errors = validateForm(formData);
            setErrors({ ...validateForm(formData) });
            if (errors && Object.values(errors).length) {
                return;
            }

            dispatch(startLoading());

            let data = {
                ...formData,
                segment: formData?.segment?.value,
                targetIndustry: formData?.targetIndustry?.value,
                kpis: formData?.kpis?.map((kpi) => kpi?.value).join(', '),
                title: formData?.title?.value,
                name: formData?.name?.value
            };
            data.tooltip = `Target Industry: ${data.targetIndustry}
            Company: ${data.companyProfile}
            KPIs: ${data.kpis}
            Linkedin: ${data.linkedinProfile}`;

            const payload = {
                ...formData,
                segment: data.segment || '',
                industry: data?.targetIndustry || '',
                companyName: data?.companyProfile || '',
                title: data?.title || '',
                linkedinUrl: data?.linkedinProfile || '',
                kpis: data.kpis || '',
                ownerId: userDetails?.id,
                ownerOrgId: userDetails?.orgs.find((org) => org.isDefault)?.code || ''
            };

            if (!isEditing) {
                await addNewAudience(payload)
                    .unwrap()
                    .then((res) => {
                        toast.success(res.message);
                        onSuccess({ ...data, ...res?.data?.audience });
                    })
                    .catch((err) => {
                        console.log('err >>>> ', err);
                        toast.error(err?.data?.message || '!Audience creation failed');
                    });
            } else {
                const { id, ownerId, ownerOrgId, ...rest } = payload;
                await updateAudience({ payload: rest, id })
                    .unwrap()
                    .then((res) => {
                        toast.success(res.message);
                        onSuccess({ ...data, ...res?.data?.audience }, isEditing);
                    })
                    .catch((err) => {
                        console.log('err >>>> ', err);
                        toast.error(err?.data?.message || '!Failed to update audinece');
                    });
            }
            handleClose();
        } catch (err) {
            console.log('err >>>> ', err);
            toast.error(err?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleClose = () => {
        setData({});
        setFormData(defaultState);
        setErrors(defaultState);
        onClose(false);
    };

    const renderFields = () => {
        const className = cx(styles.mainField, '');
        return [
            {
                label: AUDIENCE.SEGMENT,
                placeholder: 'e.g Federal, State, Local',
                value: formData.segment,
                error: errors?.segment,
                id: 'segment',
                inputType: 'createableSelect',
                selectOptions: addToOptions(segmentOptions, formData.segment),
                placement: 'bottom',
                isMulti: false
            },
            {
                label: AUDIENCE.INDUSTRY,
                placeholder: `eg. Manufacturing`,
                value: formData.targetIndustry,
                error: errors?.targetIndustry,
                id: 'targetIndustry',
                inputType: 'createableSelect',
                selectOptions: addToOptions(AUDIENCE_INDUSTRY_OPTIONS, formData.targetIndustry),
                placement: 'bottom',
                isMulti: false
            },
            {
                label: AUDIENCE.COMPANY_PROFILE,
                placeholder: 'eg. Bolt Inc.',
                value: formData.companyProfile,
                error: errors?.companyProfile,
                id: 'companyProfile'
            },
            {
                label: AUDIENCE.TITLE,
                placeholder: 'eg. Marketing Director',
                value: formData.title,
                error: errors?.title,
                id: 'title',
                inputType: 'createableSelect',
                selectOptions: addToOptions(AUDIENCE_ROLE_OPTIONS, formData.title),
                placement: 'bottom',
                isMulti: false
            },
            {
                label: AUDIENCE.LINKEDIN,
                placeholder: `eg. https://www.linkedin.com/`,
                value: formData.linkedinProfile,
                error: errors?.linkedinProfile,
                id: 'linkedinProfile',
                readonly: true
            },
            {
                label: AUDIENCE.KEY_PERFORMANCE_INDICATORS,
                placeholder: 'eg. Number of new contracts, Customer acquisition cost',
                value: formData.kpis,
                error: errors?.kpis,
                id: 'kpis',
                inputType: 'createableSelect',
                selectOptions: addToOptions(
                    AUDIENCE_ROLE_OPTIONS?.find((role) => role.value === formData.title?.value)
                        ?.kpis || []
                ),
                placement: 'bottom',
                isMulti: true
            },
            {
                label: 'Offerings',
                placeholder: 'eg. Primary Product ',
                value: formData.offerings,
                error: errors?.offerings,
                id: 'offerings',
                inputType: 'textarea'
            },
            {
                label: 'Job Responsibilities',
                placeholder: 'eg. Engagement',
                value: formData.jobResponsibilities,
                error: errors?.jobResponsibilities,
                id: 'jobResponsibilities',
                inputType: 'textarea'
            },
            {
                label: 'Personal Motivators (Emotional)',
                placeholder: 'eg. Getting to know the executive team on a personal level',
                value: formData.personalMotivators,
                error: errors?.personalMotivators,
                id: 'personalMotivators',
                inputType: 'textarea'
            },
            {
                label: 'Business Outcomes',
                placeholder: 'eg. Sense of community',
                value: formData.businessOutcomes,
                error: errors?.businessOutcomes,
                id: 'businessOutcomes',
                inputType: 'textarea'
            },
            {
                label: 'Pain Points',
                placeholder: 'eg. Being perceived as a nice to have function',
                value: formData.frustrationsAndChallenges,
                error: errors?.frustrationsAndChallenges,
                id: 'frustrationsAndChallenges',
                inputType: 'textarea'
            },
            {
                label: 'Use Cases',
                placeholder: 'eg. Company announcements',
                value: formData.useCases,
                error: errors?.useCases,
                id: 'useCases',
                inputType: 'textarea'
            },
            {
                label: 'Triggers',
                placeholder: 'eg. Shift to remote or hybrid work',
                value: formData.purchaseTriggers,
                error: errors?.purchaseTriggers,
                id: 'purchaseTriggers',
                inputType: 'textarea'
            },
            {
                label: 'Measures of Success',
                placeholder: 'eg. Inclusivity metrics',
                value: formData.successMeasures,
                error: errors?.successMeasures,
                id: 'successMeasures',
                inputType: 'textarea'
            },
            {
                label: 'Common Objection',
                placeholder: 'eg. I can`t get the budget',
                value: formData.commonObjection,
                error: errors?.commonObjection,
                id: 'commonObjection',
                inputType: 'textarea'
            },
            {
                label: 'Insight about the person',
                placeholder: 'eg. Insights about the person',
                value: formData.personInsight,
                error: errors?.personInsight,
                id: 'personInsight',
                inputType: 'textarea'
            }
        ].map((field, key) => {
            return (
                <CustomInput
                    key={key}
                    id={field.id}
                    label={field.label}
                    inputClass={styles.input}
                    value={field.value}
                    onChange={handleChange}
                    error={field.error}
                    className={className}
                    inputType={field.inputType}
                    inputProps={{
                        placeholder: field.placeholder
                    }}
                    selectOptions={field.selectOptions}
                    placement={field.placement}
                    isMulti={field.isMulti}
                    readonly={field.readonly}
                />
            );
        });
    };

    return (
        <>
            {isOpen && (
                <div className={styles.overlay}>
                    <div
                        className={cx(
                            styles.content,
                            styles.container,
                            isDark && '[&&]:text-black'
                        )}
                    >
                        <Icomoon
                            className={styles.closeButton}
                            onClick={handleClose}
                            icon={'add'}
                        />

                        <h1 className={styles.title}>
                            {isEditing ? STRINGS.EDIT_CUSTOM_AUDIENCE : STRINGS.ADD_CUSTOM_AUDIENCE}
                        </h1>
                        <form onSubmit={handleSubmit}>
                            {renderFields()}

                            <button className={cx(styles.button, styles.cta)} type="submit">
                                {isEditing ? LABELS.UPDATE : LABELS.SUBMIT}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default React.memo(AddAudience);
