//constants
import { CONTENT_FILE_TYPES } from 'src/shared/constants';
import { ERRORS } from 'src/shared/strings';
import { ANALYTICS, analytics } from 'src/shared/utils/Analytics';
import { CLAP_SESSION, PROCESSED_FILE } from 'src/shared/constants';

//helpers
import { getContentTypeLabel } from 'src/shared/utils/helpers';
import { removeHTMLTags } from 'src/shared/utils';
import { Document, Packer, Paragraph } from 'docx';
import { toast } from 'react-toastify';

export const validateData = (
    data = {
        file: [],
        contentType: [''],
        toneRegister: '',
        audience: '',
        title: '',
        goal: '',
        theme: '',
        demography: '',
        relevantProducts: '',
        subType: '',
        persona: ''
    }
) => {
    const { file, contentType, toneRegister, audience, title, subType, persona } = data;

    const errors = {};

    if (!title) {
        errors.title = `Please add campaign title!`;
    }

    if (contentType.includes('bdrCadence') && !subType) {
        errors.subType = `Please select BDR type!`;
    }

    if (!file.length) {
        errors.file = `Please add atleast one source!`;
    } else {
        file.forEach((f) => {
            if (
                (f.type === CONTENT_FILE_TYPES.PDF.value ||
                    f?.type === CONTENT_FILE_TYPES.DOCX.value) &&
                !f?.files?.length
            ) {
                errors.file = ERRORS.UPLOAD_FILE;
            } else if (f?.type === CONTENT_FILE_TYPES.TEXT.value && !f?.value?.length) {
                errors.file = ERRORS.EMPTY_CONTENT_INPUT;
            } else if (f?.type === CONTENT_FILE_TYPES.URL.value && !f?.value?.length) {
                errors.file = ERRORS.EMPTY_URL_INPUT;
            }
        });
    }

    // if (!contentType || contentType.length <= 0) {
    //     errors.contentType = ERRORS.EMPTY_CONTENT_TYPE;
    // }
    if (!toneRegister || toneRegister.length <= 0) {
        errors.toneRegister = ERRORS.REQUIRED;
    }
    if (!audience || audience.length <= 0) {
        errors.audience = ERRORS.REQUIRED;
    }

    if (!persona || persona.length <= 0) {
        errors.persona = ERRORS.REQUIRED;
    }

    return { ...errors };
};

export const generateDoc = async (title, campaignId, data, saveFile) => {
    try {
        analytics.sendClickEvent(
            'download_doc_click',
            ANALYTICS.SCREEN_NAMES.DOC_CREATER_VIEW,
            ANALYTICS.ACTIONS.DOWNLOAD_DOC_CLICK
        );
        const doc = new Document({
            sections: [
                {
                    children: [createPageHeading(title), ...processDataForPdf(data)]
                }
            ]
        });
        await Packer.toBlob(doc).then(async (blob) => {
            // saveAs(blob, 'CopyKitAIWizard.docx');
            const clapSession = sessionStorage.getItem(CLAP_SESSION);
            await saveFile({
                fileSession: clapSession,
                type: PROCESSED_FILE.value,
                fileName: `CopyKitAIWizard.docx`,
                file: new File([blob], `processed_${clapSession || campaignId?.slice(-5)}.docx`, {
                    type: 'application/msword',
                    lastModified: Date.now()
                }),
                campaignId
            }).then(() => {
                sessionStorage.clear();
            });
        });
    } catch (err) {
        toast.error(err?.data?.message);
    }
};

const createPageHeading = (data) => {
    data = removeHTMLTags(data);
    return new Paragraph({
        text: data,
        heading: 'Heading1',
        spacing: {
            after: 400,
            before: 200
        },
        alignment: 'center'
    });
};

const createParagraph = (data) => {
    data = removeHTMLTags(data);
    return new Paragraph({
        text: data,
        heading: 'title'
    });
};

const createHeading = (data) => {
    data = removeHTMLTags(data);
    return new Paragraph({
        text: data,
        heading: 'Heading2',
        spacing: {
            after: 200,
            before: 200
        }
    });
};

const createHeadingCenter = (data) => {
    data = removeHTMLTags(data);
    return new Paragraph({
        text: data,
        heading: 'Heading1',
        spacing: {
            after: 200,
            before: 500
        },
        alignment: 'center'
    });
};

const createTweets = (data) => {
    data = removeHTMLTags(data);
    return new Paragraph({
        text: data,
        heading: 'title',
        bullet: {
            level: 0
        },
        spacing: {
            before: 320
        }
    });
};

const processDataForPdf = (data) => {
    return data
        .map((target) => {
            let res = [];
            res.push(createHeadingCenter(target?.audience?.label));
            res.push(
                ...target?.choices
                    .map((choice, key) => {
                        let arr = [];
                        arr.push(createHeading(getContentTypeLabel(choice?.type)));
                        choice?.choices?.forEach((item, idx) => {
                            const paras = item?.split('\n');
                            paras?.forEach((para, key) => {
                                if (key === 0 && choice?.choices.length > 1) {
                                    arr.push(createTweets(para));
                                } else {
                                    arr.push(createParagraph(para));
                                }
                            });
                        });
                        return arr;
                    })
                    .reduce((prev, curr) => prev.concat(curr), [])
            );
            return res;
        })
        .reduce((prev, curr) => prev.concat(curr), []);
};
