const styles = {
    container: `flex flex-wrap gap-10`,
    card: `cursor-pointer flex
        border-2 border-slate-800  
        hover:border-2 hover:border-slate-100`,
    cardCount: `bg-slate-800 p-2 text-center`,
    cardTitle: `bg-white text-black p-2 text-center`
};

export default styles;
