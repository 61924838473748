import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';

//api
import { useLazyGetLeadsAnalyticsQuery } from 'src/redux/queries/Admin/Leads';

//widget
import ViewRecords from './ViewRecords';

//helpers
import { toCapitalizedWords } from 'src/shared/utils';
import { getProgressColor } from './helpers';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from 'src/redux/reducers';

interface IAnalyticsData {
    field: string;
    completion: number;
    incomplete: number;
}

const FONT_STYLE = cx(`font-apple`, `text-gray-700`);
const HEADER_STYLE = cx(`text-2xl font-semibold`);
const SUBTITLE_STYLE = cx(`text-sm text-gray-500`);
const STATS_STYLE = cx(`bg-white p-4 rounded-lg shadow-sm flex flex-col items-center text-center`);
const STAT_VALUE_STYLE = cx(`text-3xl font-bold`);
const STAT_LABEL_STYLE = cx(`text-sm text-gray-500`);

const ContactsDbAnalytics = () => {
    const [getLeadsAnalytics] = useLazyGetLeadsAnalyticsQuery();

    const [totalContacts, setTotalContacts] = useState(0);
    const [completePercentage, setCompletePercentage] = useState(`0%`);
    const [fields, setFields] = useState<IAnalyticsData[]>([]);
    const [showRecords, setShowRecords] = useState(false);
    const [recordType, setRecordType] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        fetchLeads();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchLeads = async () => {
        try {
            dispatch(startLoading());
            await getLeadsAnalytics()
                .unwrap()
                .then((data) => {
                    console.log('data >>> ', data);
                    setTotalContacts(data.total);
                    setCompletePercentage(`${data.completionPercentage}%`);
                    setFields(data.fieldAnalytics || []);
                })
                .catch((err) => {
                    console.log(err);
                    throw err;
                });
        } catch (error: any) {
            toast.error(error?.data?.msg || 'Not able to fetch contacts!');
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleCloseRecords = () => setShowRecords(false);

    return (
        <div className={cx(FONT_STYLE, 'p-8 bg-gray-100 min-h-screen w-full')}>
            {/* Header Section */}
            <div className="mb-6">
                <div className={`${FONT_STYLE} ${HEADER_STYLE}`}>
                    {'Contacts Database Health Score'}
                </div>
                <div className={`${FONT_STYLE} ${SUBTITLE_STYLE}`}>
                    {'Contact database quality metrics and enrichment status'}
                </div>
            </div>

            {/* Stats Section */}
            <div className={cx(FONT_STYLE, "grid grid-cols-4 gap-4 mb-8")}>
                <div className={STATS_STYLE}>
                    <div className={STAT_VALUE_STYLE}>{totalContacts}</div>
                    <div className={STAT_LABEL_STYLE}>Total Contacts</div>
                    {/* <div className="text-xs text-green-500">+124 this week</div> */}
                </div>
                <div className={STATS_STYLE}>
                    <div className={STAT_VALUE_STYLE}>{completePercentage}</div>
                    <div className={STAT_LABEL_STYLE}>Data Completion</div>
                    {/* <div className="text-xs text-green-500">↑ 2.1% from last month</div> */}
                </div>
                <div className={STATS_STYLE}>
                    <div className={STAT_VALUE_STYLE}>0</div>
                    <div className={STAT_LABEL_STYLE}>Needs Review</div>
                    {/* <div className="text-xs text-yellow-500">Records flagged</div> */}
                </div>
                <div className={STATS_STYLE}>
                    <div className={STAT_VALUE_STYLE}>0</div>
                    <div className={STAT_LABEL_STYLE}>Enrichment Queue</div>
                    {/* <div className="text-xs text-purple-500">Pending enrichment</div> */}
                </div>
            </div>

            {/* Field Completion Status Section */}
            <div className="bg-white p-6 rounded-lg shadow-sm">
                <div className="font-semibold text-lg mb-4">{'Field Completion Status'}</div>
                <div className="text-sm font-medium">
                    {fields.map((field, key) => {
                        const percentage = field.completion;

                        return (
                            <div key={key} className="flex justify-between items-center mb-6">
                                <div>{toCapitalizedWords(field.field)}</div>
                                <div className={cx('flex items-center')}>
                                    <div className={cx('w-[300px] h-2 bg-gray-100', 'mr-4')}>
                                        <div
                                            className={cx('h-2', `${getProgressColor(percentage)}`)}
                                            style={{ width: `${percentage}%` }}
                                        />
                                    </div>
                                    <div className="text-yellow-500 mr-4">
                                        {`${percentage}% (${field.incomplete} incomplete)`}
                                    </div>
                                    <div
                                        className={cx('text-blue-500', 'cursor-pointer')}
                                        onClick={() => {
                                            setRecordType(field.field);
                                            setShowRecords(true);
                                        }}
                                    >
                                        View Records
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            {showRecords && (
                <ViewRecords recordType={recordType} handleClose={handleCloseRecords} />
            )}
        </div>
    );
};

export default React.memo(ContactsDbAnalytics);
