//libs
import cx from 'classnames';
import { toast } from 'react-toastify';

//styles
import S from './ResetPassword.module.scss';

//constants
import { STRINGS } from 'src/shared/strings';
import { ROUTES } from 'src/shared/constants/routes';

//form
import { ResetPasswordForm } from './ResetPasswordForm';

//api
import { useResetPasswordMutation } from 'src/redux/queries/User';

//hooks
import { useDispatch } from 'react-redux';

//helpers
import { startLoading, stopLoading } from 'src/redux/reducers';
import useCustomNavigation from 'src/hooks/useCustomNavigations';

const ResetPassword = () => {
    const dispatch = useDispatch();

    const [resetPassword] = useResetPasswordMutation();
    const handleNavigation = useCustomNavigation();

    const handleSubmit = async (values: any) => {
        try {
            dispatch(startLoading());

            const payload = { ...values };

            await resetPassword(payload)
                .unwrap()
                .then((data) => {
                    if (data.statusCode === 0) {
                        toast.success(data.message, { toastId: '0' });
                        handleNavigation(ROUTES.LOGIN);
                        return;
                    }

                    toast.error(data.message, { toastId: '1' });
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(err?.data?.message || 'Failed to reset password', { toastId: '2' });
                });
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(stopLoading());
        }
    };

    return (
        <section className={S.section}>
            <div className={cx('flex gap-8', 'm-auto p-8', 'rounded shadow', 'bg-white')}>
                <div className={cx('flex flex-1 items-center justify-between')}>
                    <h3 className={cx('text-3xl')}>{STRINGS.RESET_PASSWORD}</h3>
                </div>
                <div className={'ml-1'}>
                    <h1 className={cx('text-xl font-semibold')}>{STRINGS.EMAIL_SENT}</h1>
                    <ResetPasswordForm handleSubmit={handleSubmit} />
                </div>
            </div>
        </section>
    );
};

export default ResetPassword;
