export const MIN_PASSWORD_LENGTH = 12;
export const MAX_PASSWORD_LENGTH = 24;

export const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{12,}$/;

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const GROUP_FILTERS = [
    { label: 'None', value: '' },
    { label: 'Company', value: 'companyName' },
    { label: 'Industry', value: 'industry' }
];

export enum USER_TYPES {
    ADMIN = 0
}
