import React from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

const Checkbox = ({
    className = '',
    data = { label: '', value: '' },
    isChecked = false,
    handleCheck
}) => {
    return (
        <label className={cx(styles.container, className)}>
            <input className={styles.input} type="checkbox" onChange={handleCheck} />

            <div className={styles.box}>
                <svg
                    className={cx(styles.checkbox, { [styles.checkboxActive]: isChecked })}
                    aria-hidden="true"
                    viewBox="0 0 15 11"
                    fill="none"
                >
                    <path
                        d="M1 4.5L5 9L14 1"
                        strokeWidth="2"
                        stroke={isChecked ? '#fff' : 'none'}
                    />
                </svg>
            </div>
            <div className={cx()}>{data.label}</div>
        </label>
    );
};

export default React.memo(Checkbox);
