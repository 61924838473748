import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { ICompany, ICompanyResponse } from 'src/defs';

interface IDeletePayload {
    ids: string[];
}

interface ICompaniesResponse {
    total: number;
    companies: ICompany[];
    fieldAnalytics: { field: string; completion: number; incomplete: number }[];
}

interface ICompanyQuery {
    offset?: number;
    limit?: number;
    sortBy?: string;
    order?: number;
    searchQuery?: string;
    groupBy?: string;
}

const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true'
};

let token: string = '';

export const adminCompaniesApi = createApi({
    reducerPath: 'adminCompaniesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/admin/companies',
        timeout: 60000,
        prepareHeaders: (headers, { getState }) => {
            // Specify RootState to ensure proper typing
            const state = getState() as any;
            token = state.user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getCompanies: builder.query<ICompaniesResponse, ICompanyQuery>({
            query: (payload: ICompanyQuery) => {
                const searchString = encodeURIComponent(payload.searchQuery || '');

                return {
                    method: 'GET',
                    headers: { ...headers },
                    url: `/`,
                    params: { ...payload, searchQuery: searchString }
                };
            }
        }),
        getCompany: builder.query<ICompanyResponse, string>({
            query: (id) => {
                return {
                    method: 'GET',
                    headers: { ...headers },
                    url: `/${id}`
                };
            }
        }),
        updateCompany: builder.mutation<void, { id: string; payload: any }>({
            query: ({ payload, id }) => ({
                method: 'PATCH',
                headers: { ...headers },
                url: `/${id}`,
                body: payload
            })
        }),
        deleteCompanies: builder.mutation<void, IDeletePayload>({
            query: (payload: IDeletePayload) => {
                return {
                    method: 'POST',
                    headers: { ...headers },
                    url: `/delete`,
                    body: payload.ids
                };
            }
        })
    })
});

// Exporting hooks generated by the API
export const {
    useLazyGetCompanyQuery,
    useLazyGetCompaniesQuery,
    useDeleteCompaniesMutation,
    useUpdateCompanyMutation
} = adminCompaniesApi;
