import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

//helpers
import { toast } from 'react-toastify';
import { startLoading, stopLoading } from 'src/redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import useCustomNavigation from 'src/hooks/useCustomNavigations';
import useUpdateUserDetails from 'src/hooks/useUpdateUserDetails';

//apis
import {
    useAddUserToOrgMutation,
    useLazyGetUserOrgQuery,
    useUpdateUserMutation
} from 'src/redux/queries';
import { useLazyDeletePersonaQuery, useLazyGetAllPersonasQuery } from 'src/redux/queries/Personas';
import { useLazyDeleteAudienceQuery, useLazyGetAudinecesQuery } from 'src/redux/queries/Audience';
import {
    useLazyGetAllUserOrgsQuery,
    useRemoveMemberMutation,
    useUpdateOrgUserRoleMutation
} from 'src/redux/queries/Org';

//widgets
import { Tabs } from './Widgets/Tabs';
import { AddAudience } from '../../Campaign/widgets/ContentForm/widgets/AddAudience';
import { AddPersona } from '../../Campaign/widgets/ContentForm/widgets/AddPersona';

//constants
import { ROUTES } from 'src/shared/constants/routes';
import { darkContainer } from 'src/shared/styles/colors';

const IndexPage = () => {
    const user = useSelector((state) => state.user);
    const userDetails = user.userDetails;
    const userOrgs = useMemo(() => userDetails?.orgs || [], [userDetails]);
    const isDark = user.isDarkTheme;

    const dispatch = useDispatch();
    const handleNavigation = useCustomNavigation();
    const updateUserData = useUpdateUserDetails();

    const [getAllUserOrgs] = useLazyGetAllUserOrgsQuery();
    const [getUserOrg] = useLazyGetUserOrgQuery();
    const [addUserToOrg] = useAddUserToOrgMutation();
    const [updateUser] = useUpdateUserMutation();
    const [getPersonas] = useLazyGetAllPersonasQuery();
    const [getAudiences] = useLazyGetAudinecesQuery();
    const [deletePersona] = useLazyDeletePersonaQuery();
    const [deleteAudience] = useLazyDeleteAudienceQuery();
    const [removeOrgMember] = useRemoveMemberMutation();

    const [userOrgsData, setUserOrgsData] = useState([]);
    const [defaultOrg, setDefaultOrg] = useState('');
    const [personas, setPersonas] = useState([]);
    const [audiences, setAudiences] = useState([]);
    const [activeTab, setActiveTab] = useState(1);
    const [loading, setLoading] = useState(true);
    const [showPersonaForm, setShowPersonaForm] = useState(false);
    const [showAudienceForm, setShowAudienceForm] = useState(false);
    const [personaData, setPersonaData] = useState({});
    const [audienceData, setAudienceData] = useState({});
    const [updateUserRole] = useUpdateOrgUserRoleMutation();

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const defaultOrg = userOrgs.find((uo) => uo.isDefault);
        setDefaultOrg(defaultOrg?.code);
    }, [userOrgs]);

    const fetchData = async () => {
        await fetchUserOrgs();
        await fetchPersonas();
        await fetchAudiences();
    };

    const fetchUserOrgs = useCallback(
        async () => {
            try {
                dispatch(startLoading());
                setLoading(true);
                if (!userOrgs?.length) {
                    handleNavigation(ROUTES.ORG_ONBOARDING, true);
                    return;
                }

                const orgsData = await getAllUserOrgs().unwrap();

                setUserOrgsData(orgsData?.data || []);
            } catch (err) {
                console.log(err);
                toast.error(err?.data?.message);
            } finally {
                dispatch(stopLoading());
                setLoading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const fetchPersonas = async () => {
        await getPersonas()
            .unwrap()
            .then((data) => {
                let personas = data?.data || [];
                personas = personas?.filter((persona) => persona?.userId);
                setPersonas(personas);
            });
    };

    const fetchAudiences = async () => {
        await getAudiences()
            .unwrap()
            .then((data) => {
                let targetAudience = data?.data?.map((audience) => ({
                    ...audience,
                    label: audience?.title,
                    value: audience?.title,
                    tooltip: `Target Industry: ${audience.targetIndustry || audience.industry}
                                Company: ${audience.companyProfile || audience.companyName}
                                KPIs: ${audience.kpis || audience.personalityIndicators}
                                Linkedin: ${audience.linkedinProfile || audience.linkedinUrl}`
                }));

                setAudiences(targetAudience);
            });
    };

    const handleDefaultOrg = async (e, id) => {
        try {
            dispatch(startLoading());
            let orgs = userOrgs?.map((org) => ({
                ...org,
                isDefault: org.code === id ? true : false
            }));
            await updateUser({ orgs })
                .unwrap()
                .then(async () => {
                    await updateUserData();
                    setDefaultOrg(id);
                });
        } catch (err) {
            console.log(err);
            toast.error(err?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleAddMemberToOrg = async ({ user, id }, hideModal = () => {}) => {
        try {
            dispatch(startLoading());
            await addUserToOrg({ user, id })
                .unwrap()
                .then(async (res) => {
                    hideModal();
                    toast.success(res.message);
                    let data = await getUserOrg(id).unwrap();
                    let pos = userOrgsData?.findIndex((o) => o.code === data.code);
                    let updatedOrgs = [...userOrgsData];
                    if (pos !== -1) {
                        updatedOrgs.splice(pos, 1, data);
                    }
                    setUserOrgsData([...updatedOrgs]);
                });
        } catch (err) {
            toast.error(err?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleAddNewOrg = () => {
        handleNavigation(ROUTES.ORG_ONBOARDING);
    };

    const handleShowPersonaForm = (persona, active) => {
        setShowPersonaForm(active);
        setPersonaData(persona);
    };

    const handleShowAudienceForm = (audience, active) => {
        setShowAudienceForm(active);
        setAudienceData(audience);
    };

    const handleEditOrgSuccess = (org) => {
        try {
            let pos = userOrgsData.findIndex((o) => o.code === org.code);
            if (pos !== -1) {
                let updateData = [...userOrgsData];
                updateData.splice(pos, 1, org);
                setUserOrgsData([...updateData]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleAddPersonaSuccess = (persona) => {
        let pos = personas.findIndex((per) => per._id === persona._id);
        if (pos !== -1) {
            personas.splice(pos, 1, persona);
        } else {
            personas.push(persona);
        }
        setPersonas([...personas]);
    };

    const handleAddAudienceSuccess = (audience) => {
        let pos = audiences.findIndex((aud) => aud._id === audience._id);
        if (pos !== -1) {
            audiences.splice(pos, 1, audience);
        } else {
            audiences.push(audience);
        }
        setAudiences([...audiences]);
    };

    const handleDeletePersona = async (id) => {
        try {
            dispatch(startLoading());
            let res = await deletePersona(id).unwrap();
            let pos = personas.findIndex((per) => per._id === id);
            if (pos !== -1) personas.splice(pos, 1);
            setPersonas([...personas]);
            toast.success(res?.message);
        } catch (error) {
            toast.error(error?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleDeleteAudience = async (id) => {
        try {
            dispatch(startLoading());
            let res = await deleteAudience(id).unwrap();
            let pos = audiences.findIndex((audi) => audi._id === id);
            if (pos !== -1) audiences.splice(pos, 1);
            setAudiences([...audiences]);
            toast.success(res?.message);
        } catch (error) {
            toast.error(error?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleUserRoleChange = async (data, email, org) => {
        try {
            const role = data?.target?.value?.value;
            let admins = org?.users?.filter((u) => u.type === 'admin') || [];
            if (admins?.length === 1 && admins[0]?.email === email && role === 'user') {
                return toast.error('Atleast one member should be admin');
            }
            dispatch(startLoading());
            const id = org.code;
            const user = email;
            await updateUserRole({ role, id, user })
                .unwrap()
                .then(async () => {
                    let data = await getUserOrg(id).unwrap();
                    let pos = userOrgsData?.findIndex((o) => o.code === data.code);
                    let updatedOrgs = [...userOrgsData];
                    if (pos !== -1) {
                        updatedOrgs.splice(pos, 1, data);
                    }
                    setUserOrgsData([...updatedOrgs]);
                });
        } catch (err) {
            toast.error(err?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleDeleteOrgMember = async (email, orgId) => {
        try {
            dispatch(startLoading());

            await removeOrgMember({ id: orgId, email })
                .unwrap()
                .then(async (res) => {
                    let data = await getUserOrg(orgId).unwrap();
                    await updateUserData();
                    toast.success(res?.message);
                    let pos = userOrgsData?.findIndex((o) => o.code === data.code);
                    let updatedOrgs = [...userOrgsData];
                    if (pos !== -1) {
                        updatedOrgs.splice(pos, 1, data);
                    }
                    setUserOrgsData([...updatedOrgs]);
                });
        } catch (err) {
            toast.error(err?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    return (
        <div className={cx('flex w-full', isDark && darkContainer, isDark && 'text-black')}>
            {!loading && (
                <Tabs
                    isDark={isDark}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    userOrgs={userOrgs}
                    orgsData={userOrgsData}
                    personasData={personas}
                    targetAudienceData={audiences}
                    defaultOrg={defaultOrg}
                    handleAddNewOrg={handleAddNewOrg}
                    handleShowPersonaForm={handleShowPersonaForm}
                    handleShowAudienceForm={handleShowAudienceForm}
                    handleDeleteAudience={handleDeleteAudience}
                    handleDeletePersona={handleDeletePersona}
                    handleUserRoleChange={handleUserRoleChange}
                    handleDeleteOrgMember={handleDeleteOrgMember}
                    handleAddMemberToOrg={handleAddMemberToOrg}
                    handleDefaultOrg={handleDefaultOrg}
                    handleEditOrgSuccess={handleEditOrgSuccess}
                />
            )}
            {showAudienceForm && (
                <AddAudience
                    isDark={isDark}
                    isOpen={showAudienceForm}
                    data={audienceData}
                    onClose={setShowAudienceForm}
                    onSuccess={handleAddAudienceSuccess}
                    setData={setAudienceData}
                />
            )}
            {showPersonaForm && (
                <AddPersona
                    isDark={isDark}
                    isOpen={showPersonaForm}
                    data={personaData}
                    onClose={setShowPersonaForm}
                    onSuccess={handleAddPersonaSuccess}
                    setData={setPersonaData}
                />
            )}
        </div>
    );
};

export default React.memo(IndexPage);
