import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

//hooks
import useCustomNavigation from 'src/hooks/useCustomNavigations';

//helpers
import { useLazyGetSocialUserQuery } from 'src/redux/queries';
import { startLoading, stopLoading } from 'src/redux/reducers';
import { updateToken, updateUserDetails } from 'src/redux/reducers/userReducer';

//constants
import { ROUTES } from 'src/shared/constants/routes';

const LoginSuccess = () => {
    const dispatch = useDispatch();
    const [getSocialUser] = useLazyGetSocialUserQuery();
    const handleNavigation = useCustomNavigation();

    const goToLogin = () => handleNavigation(ROUTES.LOGIN, true);
    const errorToast = () =>
        toast.error('Oops login failed!', {
            toastId: 'loginFailed'
        });

    useEffect(() => {
        try {
            dispatch(startLoading());
            setSocialCreds();
        } catch (err) {
            errorToast();
            goToLogin();
        } finally {
            dispatch(stopLoading());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setSocialCreds = async () => {
        await getSocialUser()
            .unwrap()
            .then((data) => {
                const userData = { ...data?.data?.user, data: [] };
                dispatch(updateToken(data?.data?.token));
                dispatch(updateUserDetails(userData));
                toast.success(data?.message);
            })
            .then(() => handleNavigation(ROUTES.HOME, true))
            .catch((err) => {
                errorToast();
                goToLogin();
            });
    };

    return <></>;
};

export default React.memo(LoginSuccess);
