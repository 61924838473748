import React, { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';

//constants
import { CONTENT_TYPE, PERSONA, ROUTES } from 'src/shared/constants';
import { STRINGS } from 'src/shared/strings';
import { CONTENT_FILE_TYPES } from 'src/shared/constants';

//helpers
import { ANALYTICS, analytics } from 'src/shared/utils/Analytics';
import { useLocation } from 'react-router-dom';
import { startLoading, stopLoading } from 'src/redux/reducers';
import { useDispatch } from 'react-redux';
import { validateData } from 'src/views/Private/Campaign/helpers';

//widgets
import {
    CustomButton,
    CustomInput,
    CustomInputSeparator,
    CustomTextArea,
    FileUpload
} from 'src/widgets';
import { RadioBlock } from './widgets/RadioBlock';
import CheckBlock from './widgets/CheckBlock';
import AddPersona from './widgets/AddPersona/AddPersona';
import AddAudience from './widgets/AddAudience/AddAudience';
import FilesListingModal from './widgets/FilesListingModal';
import { toast } from 'react-toastify';
import Sidebar from './widgets/Sidebar';
import { PersonalizedCampaign } from '../../Personalized';

//styles
import styles from './styles.module.scss';

//apis
import { useLazyDeletePersonaQuery } from 'src/redux/queries/Personas';
import { useLazyDeleteAudienceQuery } from 'src/redux/queries/Audience';

const ContentForm = ({
    data = {
        file: [{ files: [], value: '', type: '' }],
        contentType: [],
        toneRegister: '',
        title: '',
        seoKeywords: '',
        theme: '',
        relevantProducts: '',
        audience: []
    },
    isDark,
    updateData = () => {},
    errors = {},
    handleError = () => {},
    personas = [],
    audiences = [],
    handleSubmit = () => {},
    onAddPersonaSuccess = () => {},
    onAddAudienceSuccess = () => {}
}) => {
    const loc = useLocation();

    const dispatch = useDispatch();

    const [deletePersona] = useLazyDeletePersonaQuery();
    const [deleteAudience] = useLazyDeleteAudienceQuery();

    const [isPersonaModalOpen, setIsPersonaModalOpen] = useState(false);
    const [isAudienceModalOpen, setIsAudienceModalOpen] = useState(false);
    const [personaData, setPersonaData] = useState({});
    const [audienceData, setAudienceData] = useState({});
    const [showFileListing, setShowFileListing] = useState(false);

    const [isBdrCadence, setIsBdrCadence] = useState(false);
    const [isPersonalized, setIsPersonalized] = useState(false);

    const errorRefs = useRef({});

    useEffect(() => {
        if (loc.pathname.includes(ROUTES.CAMPAIGN_BDR_CADENCE)) {
            setIsBdrCadence(true);
            updateData({ ...data, contentType: ['bdrCadence'] });
            return;
        }

        if (loc.pathname.includes(ROUTES.CAMPAIGN_PESONALIZED)) {
            setIsPersonalized(true);
            let audiencePersona = !data.audience[0]?._id ? [] : data?.audience;
            updateData({
                ...data,
                audience: audiencePersona,
                campaignType: 'personalized',
                contentType: ['bdrCadence']
            });
            return;
        }

        setIsBdrCadence(false);
        setIsPersonalized(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loc]);

    useEffect(() => {
        document.title = 'Campaign Like a Pro - Create Campaign';
    }, []);

    const handleCheckboxSelect = (item) => {
        const idx = data?.contentType.indexOf(item?.value);

        if (idx !== -1) {
            data.contentType.splice(idx, 1);
        } else {
            data.contentType.push(item?.value);
        }
        updateData({ ...data, contentType: [...data.contentType] });
        handleError({ ...errors, contentType: '' });
        analytics.sendClickEvent(
            `content_type_${item?.value}_click`,
            ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION,
            ANALYTICS.ACTIONS.CONTENT_TYPE
        );
    };

    const handleFileUpload = async (files, index) => {
        sessionStorage.removeItem('summary');
        analytics.sendClickEvent(
            'file_upload_click',
            ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION,
            ANALYTICS.ACTIONS.FILE_UPLOAD
        );
        if (files) {
            const file = data.file;
            file[index].files = files;
            updateData({ ...data, file });
            handleError({ ...errors, file: '' });
        }
    };

    const handelTextInput = (value, index) => {
        sessionStorage.removeItem('summary');
        const file = data.file;
        file[index].value = value;
        updateData({
            ...data,
            file
        });
        handleError({ ...errors, file: '' });
    };

    const handleUrlInput = (urls = [], index) => {
        sessionStorage.removeItem('summary');
        const file = data.file;
        file[index].value = urls;
        updateData({ ...data, file });
        handleError({ ...errors, file: '' });
    };

    const onSubmit = () => {
        const errors = validateData(data);

        if (errors && Object.values(errors).length) {
            Object.keys(errors).map((field) => errorRefs?.current[field]?.focus());
        }

        handleSubmit();
    };

    const inputOptions = (types = []) => {
        return types?.map((type, key) => {
            switch (type?.type) {
                case CONTENT_FILE_TYPES.TEXT.value:
                    return (
                        <CustomTextArea
                            value={type.value}
                            onChange={(e) => handelTextInput(e, key)}
                            textAreaProps={{ autoFocus: true }}
                            key={key}
                        />
                    );
                case CONTENT_FILE_TYPES.DOCX.value:
                    return (
                        <FileUpload
                            handleUpload={(files = []) => handleFileUpload(files, key)}
                            files={type.files}
                            type={type.type}
                            multiple
                            ctaTitle={STRINGS.SELECT_DOCX}
                            ctaSelectedTitle={STRINGS.SELECTED_DOCX}
                            key={key}
                            isDraft={!loc?.state?.new}
                        />
                    );

                case CONTENT_FILE_TYPES.URL.value:
                    return (
                        <CustomInputSeparator
                            values={type.value}
                            onChange={(urls = []) => handleUrlInput(urls, key)}
                            inputProps={{ autoFocus: true }}
                            key={key}
                        />
                    );
                default:
                    return (
                        <div
                            key={key}
                            className={cx(
                                'items-center',
                                type.files?.length > 2 ? 'block' : 'flex'
                            )}
                        >
                            <CustomButton
                                title="Select existing file(s)"
                                onClick={handleShowFileListing}
                                className={cx('h-10', 'mr-4 px-6')}
                            />
                            <FileUpload
                                handleUpload={(files = []) => handleFileUpload(files, key)}
                                files={type.files}
                                multiple
                                ctaTitle={STRINGS.SELECT_PDF}
                                ctaSelectedTitle={STRINGS.SELECTED_PDF}
                                key={key}
                                isDraft={!loc?.state?.new}
                            />
                        </div>
                    );
            }
        });
    };

    const handleShowFileListing = () => {
        setShowFileListing(true);
    };

    const handleCloseFileListing = () => {
        setShowFileListing(false);
    };

    const handleFileSelect = (files) => {
        if (files.length) {
            const file = [...data.file];
            const index = data.file?.findIndex((file) => file.type === 'pdf');
            file[index].files = files;
            updateData({ ...data, file });
            handleError({ ...errors, file: '' });
        }
    };

    const setContentInputType = (type) => {
        let typeObject = { type };
        const file = data.file;
        const pos = file.findIndex((f) => f.type === type);
        if (pos !== -1) {
            file.splice(pos, 1);
        } else {
            file.push(typeObject);
        }

        sessionStorage.removeItem('summary');

        updateData({ ...data, file: [...file] });
        handleError({ ...errors, file: '' });
        analytics.sendClickEvent(
            `source_asset_${type}_click`,
            ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION,
            ANALYTICS.ACTIONS.SELECT_SOURCE_TYPE
        );
    };

    const PERSONAS = useMemo(() => {
        if (personas?.length) return [...personas];
        return [...PERSONA];
    }, [personas]);

    const handleSelectPersona = (persona) => {
        updateData({
            ...data,
            persona: persona,
            toneRegister: persona.tone
        });
        handleError({ ...errors, persona: '' });
    };

    const handleSelectAudience = (audience) => {
        let selectedAudience = [...data.audience];
        let pos = selectedAudience.findIndex((audi) => audi.value === audience.value);

        if (pos !== -1) {
            selectedAudience.splice(pos, 1);
        } else {
            selectedAudience.push(audience);
        }
        updateData({
            ...data,
            audience: selectedAudience
        });
        handleError({ ...errors, audience: '' });
        analytics.sendClickEvent(
            `audience_type_${audience?.value}_click`,
            ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION,
            ANALYTICS.ACTIONS.SELECT_AUDIENCE_TYPE
        );
    };

    const handleToneChange = (tone) => {
        updateData({ ...data, toneRegister: tone.value });
        handleError({ ...errors, toneRegister: '' });
        analytics.sendClickEvent(
            `tone_type_${tone.value}_click`,
            ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION,
            ANALYTICS.ACTIONS.SELECT_TONE_TYPE
        );
    };

    const handleFieldInputChange = (e) => {
        const { id, value } = e?.target;
        updateData({ ...data, [id]: value });
        handleError({ ...errors, [id]: '' });
    };

    const handleOrgUpdate = (e) => {
        const { label, value } = e?.target?.value;
        updateData({ ...data, orgCode: value, orgName: label });
    };

    const renderAddPersona = () => {
        const handleAddPersonaSuccess = (persona, isEditing) => {
            if (isEditing) {
                let pos = personas.findIndex((per) => per._id === persona._id);
                if (pos !== -1) personas.splice(pos, 1, persona);
                onAddPersonaSuccess([...personas] || []);
            } else {
                onAddPersonaSuccess([...personas, persona] || []);
            }
            handleSelectPersona(persona);
        };

        return (
            <AddPersona
                isOpen={isPersonaModalOpen}
                onClose={setIsPersonaModalOpen}
                onSuccess={handleAddPersonaSuccess}
                data={personaData}
                setData={setPersonaData}
            />
        );
    };

    const renderAddAudience = () => {
        const handleAddAudienceSuccess = (audience, isEditing) => {
            if (isEditing) {
                let pos = audiences.findIndex((audi) => audi._id === audience.id);
                if (pos !== -1) audiences.splice(pos, 1, audience);
                onAddAudienceSuccess([...audiences] || []);
            } else {
                onAddAudienceSuccess([...audiences, audience] || []);
            }
            handleSelectAudience(audience);
        };

        return (
            <AddAudience
                isOpen={isAudienceModalOpen}
                onClose={setIsAudienceModalOpen}
                onSuccess={handleAddAudienceSuccess}
                data={audienceData}
                setData={setAudienceData}
            />
        );
    };

    const handleAddPerson = (data) => {
        if (data) {
            setPersonaData(data);
        }
        setIsPersonaModalOpen(true);
    };

    const handleAddAudience = (data) => {
        if (data) {
            setAudienceData(data);
        }
        setIsAudienceModalOpen(true);
    };

    const handleDeletePersona = async (id) => {
        try {
            dispatch(startLoading());
            let res = await deletePersona(id).unwrap();
            let pos = personas.findIndex((per) => per._id === id);
            if (pos !== -1) personas.splice(pos, 1);
            onAddPersonaSuccess([...personas] || []);
            toast.success(res?.message);
        } catch (error) {
            toast.error(error?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleDeleteAudience = async (id) => {
        try {
            dispatch(startLoading());
            let res = await deleteAudience(id).unwrap();
            let pos = audiences.findIndex((audi) => audi._id === id);
            if (pos !== -1) audiences.splice(pos, 1);
            onAddAudienceSuccess([...audiences] || []);
            toast.success(res?.message);
        } catch (error) {
            toast.error(error?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    const renderMain = () => {
        const className = cx(styles.mainField, '');
        const renderFields = () => {
            const Fields = [
                {
                    label: STRINGS.CAMPAIGN_NAME,
                    placeholder: '',
                    value: data.title,
                    error: errors?.title,
                    id: 'title',
                    ref: (el) => (errorRefs.current['title'] = el)
                },
                {
                    label: STRINGS.CAMPAIGN_THEME,
                    placeholder: '',
                    value: data.theme,
                    error: errors?.theme,
                    id: 'theme',
                    tooltip: 'Add a few words or a line about what this campaign is about.',
                    ref: (el) => (errorRefs.current['theme'] = el)
                },
                {
                    label: STRINGS.CAMPAIGN_RELEVANT_PRODUCTS,
                    placeholder: '',
                    value: data.relevantProducts,
                    error: errors?.relevantProducts,
                    id: 'relevantProducts',
                    ref: (el) => (errorRefs.current['relevantProducts'] = el)
                },
                {
                    label: STRINGS.SEO_KEYWORDS,
                    placeholder: '',
                    value: data.seoKeywords,
                    error: errors?.seoKeywords,
                    id: 'seoKeywords',
                    tooltip: 'Add 5-10 keywords relevant for this campaign',
                    ref: (el) => (errorRefs.current['seoKeywords'] = el)
                }
            ];

            if (isBdrCadence || isPersonalized) {
                Fields.splice(1, 3);
                Fields.push({
                    label: STRINGS.BDR_TYPE,
                    placeholder: '',
                    value: data.subType,
                    error: errors?.subType,
                    id: 'subType',
                    tooltip: '',
                    inputType: 'select',
                    selectOptions: isPersonalized
                        ? BDR_CADENCE_OPTIONS.slice(3)
                        : BDR_CADENCE_OPTIONS,
                    placement: 'bottom',
                    isMulti: false
                });
            }

            if (!isBdrCadence && !isPersonalized) {
                Fields.push({
                    label: STRINGS.INSTRUCTIONS,
                    placeholder: '',
                    value: data.instructions,
                    error: errors?.instructions,
                    id: 'instructions',
                    tooltip: 'Add instructions for this campaign',
                    inputType: 'textarea',
                    customCss: { flex: 1 }
                });
            }

            return Fields.map((field, key) => {
                return (
                    <CustomInput
                        key={key}
                        id={field.id}
                        label={field.label}
                        inputClass={styles.mainInput}
                        value={field.value}
                        onChange={handleFieldInputChange}
                        error={field.error}
                        inputType={field.inputType}
                        className={className}
                        selectOptions={field.selectOptions}
                        placement={field.placement}
                        tooltipText={field.tooltip}
                        isMulti={field.isMulti}
                        labelClass={styles.label}
                        customCss={field.customCss}
                        inputProps={{ ref: field.ref }}
                    />
                );
            });
        };

        return (
            <div className={styles.main}>
                <div className={cx('flex flex-wrap w-100 justify-between border-b-2 pb-2')}>
                    {renderFields()}
                </div>

                <div className={styles.doc}>
                    <span
                        tabIndex="0"
                        ref={(el) => (errorRefs.current['file'] = el)}
                        className={styles.configureTitleError}
                    >
                        {errors?.file}
                    </span>
                    {inputOptions(data.file)}
                </div>

                {PERSONAS?.length >= 1 && (
                    <RadioBlock
                        heading={STRINGS.WRITER_PERSONA}
                        headingTooltip={STRINGS.PERSONA_TOOLTIP}
                        data={PERSONAS}
                        error={errors?.persona || errors?.toneOutlook}
                        onSelect={handleSelectPersona}
                        value={data.persona?.name}
                        allowAdd
                        handleAdd={handleAddPerson}
                        handleDelete={handleDeletePersona}
                        deleteTitle={STRINGS.DELETE_PERSONA}
                        deleteDesc={STRINGS.DELETE_PERSONA_DESC}
                    />
                )}

                {!isBdrCadence && !isPersonalized && (
                    <CheckBlock
                        heading={STRINGS.SELECT_CONTENT_TYPE}
                        headingTooltip={STRINGS.SELECT_CONTENT_TYPE_TOOLTIP}
                        data={CONTENT_TYPE}
                        error={errors?.contentType}
                        onSelect={handleCheckboxSelect}
                        selectedValues={data?.contentType}
                        errorRef={(el) => (errorRefs.current['contentType'] = el)}
                    />
                )}

                {!isPersonalized && (
                    <CheckBlock
                        heading={STRINGS.AUDIENCE_TYPE}
                        data={audiences.filter((audi) => audi.type !== 'personalized')}
                        error={errors?.audience}
                        onSelect={handleSelectAudience}
                        selectedValues={data.audience}
                        allowAdd
                        handleAdd={handleAddAudience}
                        handleDelete={handleDeleteAudience}
                        deleteTitle={STRINGS.DELETE_AUDIENCE}
                        deleteDesc={STRINGS.DELETE_AUDIENCE_DESC}
                        errorRef={(el) => (errorRefs.current['audience'] = el)}
                    />
                )}

                <div className={styles.mainFooter}>
                    <CustomButton title={STRINGS.NEXT} onClick={onSubmit} />
                </div>
            </div>
        );
    };

    const handleUpdateAudiencePersona = (audiencePersona) => {
        let audience = [...data.audience];
        let pos = audience.findIndex((audi) => audi._id === audiencePersona._id);
        if (pos !== -1) {
            audience.splice(pos, 1, audiencePersona);
        } else {
            audience.push(audiencePersona);
        }
        updateData({ ...data, audience: audience });
    };

    const handleSelectAudiencePersona = (audiencePersona) => {
        let selectedAudience = [...data.audience];
        let pos = selectedAudience.findIndex((audi) => audi._id === audiencePersona._id);

        if (pos !== -1) {
            selectedAudience.splice(pos, 1);
        } else {
            selectedAudience.push(audiencePersona);
        }
        updateData({
            ...data,
            audience: selectedAudience
        });
        handleError({ ...errors, audience: '' });
        analytics.sendClickEvent(
            `audience_type_${audiencePersona?._id}_click`,
            ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION,
            ANALYTICS.ACTIONS.SELECT_AUDIENCE_TYPE
        );
    };

    const renderSteps = () => {
        return (
            <>
                <PersonalizedCampaign
                    data={data}
                    audiences={audiences.filter((a) => a.type === 'personalized')}
                    updateData={handleUpdateAudiencePersona}
                    handleSelectAudience={handleSelectAudiencePersona}
                />
                {!!data.audience?.length && (
                    <div
                        className={cx(
                            'rounded-lg shadow',
                            'm-5 p-4',
                            isDark ? 'bg-slate-800' : 'bg-white'
                        )}
                    >
                        <div className={cx('py-2 px-2')}>
                            <h1 className={cx('text-xl font-semibold')}>{'2. Campaign Details'}</h1>
                        </div>
                        {renderForm()}
                    </div>
                )}
            </>
        );
    };

    const renderForm = () => {
        return (
            <div className={styles.container}>
                <Sidebar
                    data={data}
                    errors={errors}
                    updateData={updateData}
                    handleError={handleError}
                    setContentInputType={setContentInputType}
                    handleToneChange={handleToneChange}
                    handleOrgUpdate={handleOrgUpdate}
                />
                {renderMain()}
                {isPersonaModalOpen && renderAddPersona()}
                {isAudienceModalOpen && renderAddAudience()}

                {showFileListing && (
                    <FilesListingModal
                        orgCode={data.orgCode}
                        closeModal={handleCloseFileListing}
                        handleFileSelect={handleFileSelect}
                        files={data.file?.find((file) => file.type === 'pdf')?.files || []}
                        containerClass={cx(styles.listing)}
                    />
                )}
            </div>
        );
    };

    return <>{isPersonalized ? renderSteps() : renderForm()}</>;
};

export default React.memo(ContentForm);

const BDR_CADENCE_OPTIONS = [
    {
        label: 'Cold Lead',
        value: 'bdr_coldLead'
    },
    {
        label: 'Warm Lead',
        value: 'bdr_warmLead'
    },
    {
        label: 'Engaged Lead',
        value: 'bdr_engagedLead'
    },
    {
        label: 'Custom Combined Templates',
        value: 'bdr_customCombinedTemplates'
    }
    // {
    //     label: 'Custom Template 1',
    //     value: 'bdr_customTemplate_1'
    // },
    // {
    //     label: 'Custom Template 2',
    //     value: 'bdr_customTemplate_2'
    // },
    // {
    //     label: 'Custom Template 3',
    //     value: 'bdr_customTemplate_3'
    // }
];
