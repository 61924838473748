import React, { useRef, useState } from 'react';
import cx from 'classnames';

//assets
import Icomoon from 'src/assets/Icomoon';

//analytics
import { ANALYTICS, analytics } from 'src/shared/utils/Analytics';

//helpers
import { getContentTypeLabel, highlightDifferences } from 'src/shared/utils/helpers';
import { formatMarkdown } from 'src/shared/utils';

//widgets
import { CustomButton, CustomTextArea } from 'src/widgets';
import { Zoom, toast } from 'react-toastify';
import Feedback from '../Feedback';
import ShareContent from 'src/components/ShareContent';

//styles
import styles from './styles.module.scss';

//constants
import { LABELS } from 'src/shared/strings';

const BdrMail = ({
    choice = {},
    targetKey,
    pos,
    audience,
    cancelEdit,
    saveData = () => {},
    updateResult,
    maxWords,
    handleRangeChange,
    handleRegenerate,
    regeneratedChoice = {},
    handleFeedbackSubmit,
    feedback,
    isDark
}) => {
    const [editPosition, setEditPosition] = useState([0, 1, 2]);
    const textref = [useRef(null), useRef(null), useRef(null)];

    const renderSendMail = (data) => {
        return <ShareContent data={data} type={'BDR Email'} />;
    };

    const emptyEditPosition = (idx) => {
        let positions = editPosition;
        let pos = positions.indexOf(idx);
        positions.splice(pos, 1);
        setEditPosition(positions);
    };

    const renderIcons = (idx, isRegenerated) => {
        if (editPosition.includes(idx)) {
            return (
                <div className={styles.icons}>
                    <Icomoon
                        className={styles.iconsCancel}
                        icon={'cancel'}
                        onClick={() => {
                            cancelEdit();
                            emptyEditPosition(idx);
                        }}
                    />

                    <Icomoon
                        className={styles.iconsAccept}
                        icon={'accept'}
                        onClick={() => {
                            saveData();
                            emptyEditPosition(idx);
                        }}
                    />
                </div>
            );
        }

        return (
            <Icomoon
                className={styles.iconsPencil}
                icon={'pencil'}
                onClick={() => {
                    if (isRegenerated) {
                        toast.info('Please Accept one choice to start editing', {
                            toastId: 1,
                            transition: Zoom
                        });
                        return;
                    }
                    setEditPosition((state) => [...state, idx]);
                    analytics.sendClickEvent(
                        ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
                        ANALYTICS.ACTIONS.EDIT_CHOICE
                    );
                }}
            />
        );
    };

    const renderTextBox = (content, pos, idx, type) => {
        return (
            <CustomTextArea
                value={content}
                showCount={false}
                onChange={(value) => updateResult(value, targetKey, pos, idx, type)}
            />
        );
    };

    const renderRegenerateCta = (key, type, isEditing) => {
        const contentFeedback =
            feedback?.find((feed) => feed.contentType === type && feed.position === key) || null;
        return (
            <div
                className={cx(styles.containerContentAction, isDark && 'bg-slate-800 py-2 rounded')}
            >
                <label htmlFor={`${choice.type}maxWords`}>
                    &asymp; Words : {maxWords[choice.type]}
                </label>
                <input
                    id={`${choice.type}maxWords`}
                    type={'range'}
                    value={maxWords[choice.type]}
                    step={10}
                    min={10}
                    max={800}
                    onChange={(e) => handleRangeChange(e, choice?.type)}
                    className={styles.wordRange}
                />
                <CustomButton
                    gradient
                    title={LABELS.REGENERATE}
                    onClick={() => {
                        handleRegenerate(audience, targetKey, key, choice.type);
                    }}
                />
                <Feedback
                    contentFeedback={contentFeedback}
                    handleFeedbackSubmit={(isLiked, comment) =>
                        handleFeedbackSubmit(choice.type, audience?.label, isLiked, comment, key)
                    }
                />
            </div>
        );
    };

    const acceptChoiceCta = (value, pos, idx, type) => {
        return (
            <CustomButton
                gradient
                title={LABELS.ACCEPT}
                onClick={() => {
                    updateResult(value, targetKey, pos, idx, type, true);
                }}
            />
        );
    };

    const renderList = (list, pos, type, regeneratedList = []) => {
        let str1, str2;
        return (
            <div className={styles.list}>
                {list?.map((item, idx) => {
                    let regeneratedString = regeneratedList[idx];
                    str1 = item;
                    str2 = regeneratedString;
                    regeneratedString = highlightDifferences(str1, regeneratedString, styles.diff);
                    return (
                        <div
                            className={styles.listItem}
                            key={idx}
                            style={{ minHeight: textref[idx]?.current?.offsetHeight }}
                        >
                            {editPosition.includes(idx) ? (
                                <div className={styles.listItemContainer}>
                                    <div className={styles.listItemContainerWrap}>
                                        {renderTextBox(str1, pos, idx, type)}
                                        {!!regeneratedString && (
                                            <div className={styles.listItemContainerCta}>
                                                {acceptChoiceCta(str1, pos, idx, type)}
                                            </div>
                                        )}
                                    </div>
                                    {!!regeneratedString && (
                                        <div className={styles.listItemContainerWrap}>
                                            <div
                                                className={styles.listItemContainerText}
                                                ref={textref[idx]}
                                                dangerouslySetInnerHTML={{
                                                    __html: regeneratedString.replaceAll(
                                                        '\n',
                                                        '<br />'
                                                    )
                                                }}
                                            />
                                            <div className={styles.listItemContainerCta}>
                                                {acceptChoiceCta(str2, pos, idx, type)}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className={styles.listItemContainer}>
                                    <div className={styles.listItemContainerWrap}>
                                        <div
                                            className={styles.listItemContainerText}
                                            ref={textref[idx]}
                                            dangerouslySetInnerHTML={{
                                                __html: str1?.replaceAll('\n', '<br />')
                                            }}
                                        />
                                        {!!regeneratedString && (
                                            <div className={styles.listItemContainerCta}>
                                                {acceptChoiceCta(str1, pos, idx, type)}
                                            </div>
                                        )}
                                    </div>
                                    {!!regeneratedString && (
                                        <div className={styles.listItemContainerWrap}>
                                            <div
                                                className={styles.listItemContainerText}
                                                ref={textref[idx]}
                                                dangerouslySetInnerHTML={{
                                                    __html: formatMarkdown(
                                                        regeneratedString
                                                    ).replaceAll('\n', '<br />')
                                                }}
                                            />
                                            <div className={styles.listItemContainerCta}>
                                                {acceptChoiceCta(str2, pos, idx, type)}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className={styles.listItemIcons}>
                                {/* {renderIcons(idx, !!regeneratedString)} */}
                                {renderSendMail(str1)}
                            </div>
                            {renderRegenerateCta(idx, type, editPosition.includes(idx))}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.wrapperTitle}>{getContentTypeLabel(choice.type)}</h1>
            {renderList(choice?.choices, pos, choice?.type, regeneratedChoice?.choices)}
        </div>
    );
};

export default React.memo(BdrMail);
