import React from 'react';
import { Oval } from 'react-loader-spinner';

//styles
import styles from './styles.module.scss';

//helpers
import { useSelector } from 'react-redux';

//defs
import type { RootState } from 'src/redux/store';

interface IProps {
    progress: number;
    progressText: string;
}

const CustomProgressBar = ({ progress, progressText }: IProps) => {
    const user = useSelector((state: RootState) => state.user);
    const isDark = user.isDarkTheme;

    return (
        <div className={styles.container}>
            <div className={styles.bar} style={{ width: `${progress}%` }}>
                {progress > 0 && <div className={styles.shimmer} />}
            </div>
            {progress < 10 && (
                <div className={styles.text}>
                    <Oval
                        visible={true}
                        height="24"
                        width="24"
                        color={isDark ? '#fff' : '#000000'}
                        secondaryColor={isDark ? '#fff' : '#000000'}
                        ariaLabel="oval-loading"
                        strokeWidth={5}
                    />
                </div>
            )}
        </div>
    );
};

export default React.memo(CustomProgressBar);
