//hook
import { useDispatch } from 'react-redux';

//api
import { useGetResultsMutation } from 'src/redux/queries';

//helpers
import { toast } from 'react-toastify';
import { startLoading, stopLoading } from 'src/redux/reducers';

const useRephrase = () => {
    const dispatch = useDispatch();

    const [getResults] = useGetResultsMutation();

    const getRephrasedText = async (highlighted = '', paragraph: string, instructions = '') => {
        // move this to config
        let prompt = `In the given content: ${paragraph}. Rephrase the following: ${highlighted}. Note: Adjust the rephrased text inside the original content. only Rephrase the highlighted segment while maintaining consistency and coherence with the context of the entire content. Ensure the rephrased text aligns seamlessly with the contents's overall theme, tone, and style, preserving the original meaning and enhancing clarity where possible. Keep the original formatting of the content`;

        let payload = { message: prompt, mType: 1, instructions: '' };
        if (instructions) {
            payload.instructions = instructions;
        }

        try {
            dispatch(startLoading());
            const result = await getResults(payload).unwrap();
            const choice = result?.choices[0];
            return choice || paragraph;
        } catch (error: any) {
            toast.error(error?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    return getRephrasedText;
};

export default useRephrase;
