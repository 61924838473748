export const ICON_HOME = 'icon-home';
export const ICON_TAG = 'icon-tag';
export const ICON_THUMBS_DOWN = 'icon-thumbs-down';
export const ICON_THUMBS_UP = 'icon-thumbs-up';
export const ICON_BIN = 'icon-bin';
export const ICON_ADD = 'icon-add';
export const ICON_PENCIL = 'icon-pencil';
export const ICON_EYE = 'icon-eye';
export const ICON_EYE_OFF = 'icon-eye-off';
export const ICON_COMMAND = 'icon-command';
export const ICON_CANCEL = 'icon-cancel';
export const ICON_SEARCH = 'icon-search';
export const ICON_FILTER = 'icon-filter';
export const ICON_HUBSPOT = 'icon-hubspot';
export const ICON_LINKEDIN = 'icon-linkedin';
export const ICON_USERS = 'icon-users';
export const ICON_CASE_STUDY = 'icon-case-study';
export const ICON_ORGANIZATION = 'icon-organization';
export const ICON_ASCENDING = 'icon-ascending';
export const ICON_DESCENDING = 'icon-descending';
export const ICON_ARROW_LEFT = 'icon-arrow-left';
export const ICON_ARROW_RIGHT = 'icon-arrow-right';
export const ICON_BADGE = 'icon-badge';
export const ICON_SHIELD = 'icon-shield';
export const ICON_LIGHTENING = 'icon-lightning';
export const ICON_HEART = 'icon-heart';
export const ICON_OFFER = 'icon-offer';
export const ICON_CHART = 'icon-chart';
export const ICON_BULB = 'icon-bulb';
export const ICON_PROFILE = 'icon-profile';
export const ICON_CLOCK = 'icon-clock';
export const ICON_STAR = 'icon-star';
export const ICON_TRIANGLE_RIGHT = 'icon-triangle-right';
export const ICON_TRIANGLE_DOWN = 'icon-triangle-down';
export const ICON_ACCEPT = 'icon-accept';
export const ICON_HOURGLASS = 'icon-hourglass';
export const ICON_GRATIUDE = 'icon-gratitude';
export const ICON_WARNING = 'icon-warning';
export const ICON_DISCOUNT = 'icon-discount';
export const ICON_HEADPHONES = 'icon-headphones';
export const ICON_GLOBE = 'icon-globe';
export const ICON_DATABSE = 'icon-database';
export const ICON_CALL = 'icon-call';
export const ICON_CHEVRON_UP = 'icon-chevron-up';
export const ICON_COMPARE = 'icon-compare';
export const ICON_HEADLINE = 'icon-headline';
export const ICON_TESTIMONIAL = 'icon-testimonial';
export const ICON_TREND = 'icon-trend';
export const ICON_VISUAL_EFFECT = 'icon-visual-effect';
export const ICON_LINK = 'icon-link';
export const ICON_LOCATION = 'icon-location';
export const ICON_BUILDING = 'icon-building';
export const ICON_TARGET = 'icon-target';
export const ICON_MOTORWAY = 'icon-motorway';
export const ICON_WALLET = 'icon-wallet';
export const ICON_STUDY = 'icon-study';
export const ICON_EYE_DROPPER = 'icon-eyedropper';
export const ICON_COMPUTER = 'icon-computer';
export const ICON_FLAG_CHECKERED = 'icon-flag-checkered';
export const ICON_ID_CARD = 'icon-id-card';
export const ICON_MAN = 'icon-man';
export const ICON_HAMMER = 'icon-hammer';
export const ICON_SAVE = 'icon-save';
export const ICON_FB = 'icon-fb';
export const ICON_LOCK = 'icon-lock';
export const ICON_CONSTRUCTION = 'icon-construction';
export const ICON_TRIANGLE_UP = 'icon-triangle-up';
export const ICON_ROBOT = 'icon-robot';
export const ICON_BOOK = 'icon-book';
export const ICON_CONTACTS = 'icon-contacts';
export const ICON_FOLDER = 'icon-folder';
export const ICON_LAYERS = 'icon-layers';
