import React, { useEffect, useState } from 'react';
import cx from 'classnames';

// widgets
import ContactsTable from 'src/views/Private/Contacts/widgets/ContactsTable';
// import Filters from 'src/views/Private/Contacts/widgets/Filters';

// constants
import { FILTERS } from 'src/views/Private/Contacts/widgets/Filters/constants';
import { darkContainer } from 'src/shared/styles/colors';

// styles
import styles from './Details.module.scss';

//redux
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

const Details = () => {
    const isDark = useSelector((state: RootState) => state.user.isDarkTheme);

    const [selectedFilters, setSelectedFilters] = useState<
        { label: string; value: string; selected?: boolean }[]
    >(() => FILTERS.map((filters) => ({ ...filters, selected: true })));

    const [tableFilters, setTableFilters] = useState(() => FILTERS);

    useEffect(() => {
        setTableFilters(() => selectedFilters.filter((filter) => filter.selected));
    }, [selectedFilters]);

    return (
        <div
            className={cx(
                styles.container,
                'flex gap-2',
                'pt-2 pr-2 pl-3',
                isDark && darkContainer
            )}
        >
            <ContactsTable selectedFilters={tableFilters} />
        </div>
    );
};

export default Details;
