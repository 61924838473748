import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';

// defs
import { updateTheme } from 'src/redux/reducers/userReducer';

//helpers
import type { RootState } from 'src/redux/store';

const labelStyle = cx('label flex items-center font-medium text-black');

const Toggle = () => {
    const dispatch = useDispatch();
    const isDarkTheme = useSelector((state: RootState) => state.user.isDarkTheme);

    const [isChecked, setIsChecked] = useState<boolean>(isDarkTheme);

    const handleCheckboxChange = () => {
        const newTheme = !isChecked;
        setIsChecked(newTheme);
        dispatch(updateTheme(newTheme));
    };

    return (
        <div className="">
            <label className="themeSwitcherTwo relative inline-flex cursor-pointer select-none items-center">
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className="sr-only"
                />
                <span className={cx(labelStyle, isChecked && 'text-white')}>Light</span>
                <span
                    className={`slider mx-2 flex h-6 w-[40px] items-center rounded-full p-1 duration-200 ${
                        isChecked ? 'bg-[#212b36]' : 'bg-[#CCCCCE]'
                    }`}
                >
                    <span
                        className={`dot h-4 w-4 rounded-full bg-white duration-200 ${
                            isChecked ? 'translate-x-[16px]' : ''
                        }`}
                    ></span>
                </span>
                <span className={cx(labelStyle, isChecked && 'text-white')}>Dark</span>
            </label>
        </div>
    );
};

export default React.memo(Toggle);
