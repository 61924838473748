import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import Select, { MenuPlacement } from 'react-select';
import CreatableSelect from 'react-select/creatable';

//styles
import styles from './styles.module.scss';

//widgets
import FileUpload from 'src/widgets/FileUpload';
import Tooltip from 'src/components/Tooltip';

//constant
import { STRINGS } from 'src/shared/strings';

//defs
import type { IFile } from 'src/defs';

interface IValue {
    value: string | { label: string; value: string } | { label: string; value: string }[];
}

interface IProps {
    className?: string;
    value: IValue['value'];
    onChange: any;
    id?: string;
    inputProps?: {
        ref?: any;
        placeholder?: string;
    };
    inputClass?: string;
    labelClass?: string;
    label?: string;
    error?: string;
    type?: string;
    icon?: string;
    inputType?: 'text' | 'textarea' | 'select' | 'creatableSelect' | string;
    max?: number;
    selectOptions?: { label: string; value: string }[];
    fileUpload?: boolean;
    files?: [];
    handleUpload?: (files: any, id: string) => void;
    readonly?: boolean;
    tooltipText?: string;
    placement?: MenuPlacement;
    isMulti?: boolean;
    autoFocus?: boolean;
    customCss?: CSSProperties;
    rows?: number;
    inputRef?: React.LegacyRef<HTMLTextAreaElement>;
    optional?: boolean;
    iconClass?: string;
    handleIconClick?: VoidFunction;
}

const CustomInput = ({
    className = '',
    value,
    onChange,
    id = '',
    inputProps = { ref: null },
    inputClass = '',
    labelClass = '',
    label = '',
    error = '',
    type = 'text',
    icon = '',
    inputType = 'text',
    max = undefined,
    selectOptions = [],
    fileUpload = false,
    files = [],
    handleUpload = () => {},
    readonly = false,
    tooltipText = '',
    placement,
    isMulti = true,
    autoFocus = false,
    customCss = {},
    rows = 4,
    inputRef,
    optional = false,
    iconClass,
    handleIconClick
}: IProps) => {
    const [selected, setSelected] = useState<IValue['value']>('');
    const tooltipRef = useRef(null);

    useEffect(() => {
        setSelected(value);
    }, [value]);

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            border: `1px solid rgba(75, 85, 99, 0.2)`,
            borderColor: 'inherit',
            boxShadow: `0px 0px 1px 0px rgba(0, 0, 0, 0.2)`,
            ':hover': {
                borderColor: 'rgba(75, 85, 99, 0.2)'
            }
        }),
        multiValue: (provided: any) => ({
            ...provided,
            backgroundColor: '#0c6084b3',
            borderRadius: 4
        }),
        multiValueLabel: (provided: any) => ({
            ...provided,
            color: 'white',
            fontSize: 16
        }),
        multiValueRemove: (provided: any) => ({
            ...provided,
            color: 'white',
            ':hover': {
                background: '#0c6084'
            }
        })
    };

    const renderInput = (inputType: string) => {
        switch (inputType) {
            case 'textarea':
                return (
                    <div className={styles.textarea}>
                        <textarea
                            rows={rows}
                            className={cx(
                                styles.input,
                                { [styles.inputIcon]: icon },
                                inputClass,
                                'text-black'
                            )}
                            value={typeof value === 'string' ? value : ''}
                            onChange={onChange}
                            id={id}
                            maxLength={max}
                            autoComplete="off"
                            {...inputProps}
                            readOnly={readonly}
                            autoFocus={autoFocus}
                            ref={inputRef}
                        />
                        {fileUpload && (
                            <FileUpload
                                files={files}
                                multiple
                                type=""
                                containerClass={styles.textareaBtn}
                                handleUpload={(files: IFile[]) => handleUpload(files, id)}
                                isDisabled={readonly}
                            />
                        )}
                    </div>
                );
            case 'select':
                return (
                    <Select
                        value={selected}
                        isMulti={isMulti}
                        menuPlacement={placement || 'top'}
                        onChange={(e) => onChange({ target: { value: e, id } })}
                        options={selectOptions}
                        placeholder={inputProps?.placeholder || ''}
                        className={cx(styles?.select, 'text-black')}
                        styles={customStyles}
                        isDisabled={readonly}
                        ref={inputProps?.ref}
                    />
                );

            case 'createableSelect':
                return (
                    <CreatableSelect
                        value={selected}
                        isMulti={isMulti}
                        menuPlacement={placement || 'top'}
                        onChange={(e) => onChange({ target: { value: e, id } })}
                        options={selectOptions}
                        placeholder={inputProps?.placeholder || ''}
                        className={cx(styles.select, 'text-black')}
                        styles={customStyles}
                        isDisabled={readonly}
                        ref={inputProps?.ref}
                    />
                );
            default:
                return (
                    <input
                        className={cx(
                            styles.input,
                            { [styles.inputIcon]: icon },
                            inputClass,
                            readonly && styles.readonly,
                            'text-black'
                        )}
                        value={typeof value === 'string' ? value : ''}
                        onChange={onChange}
                        id={id}
                        maxLength={max}
                        type={type}
                        autoComplete="off"
                        {...inputProps}
                        readOnly={readonly}
                        autoFocus={autoFocus}
                    />
                );
        }
    };

    return (
        <div style={customCss} className={cx(styles.container, className)}>
            {(label || error) && (
                <div className={styles.info}>
                    {!!!tooltipText ? (
                        <div
                            className={cx(styles.infoLabel, labelClass, {
                                [styles.readonly]: readonly
                            })}
                        >
                            {label}
                            {optional && (
                                <sup className={cx('text-xs text-neutral-500')}>
                                    ({STRINGS.OPTIONAL})
                                </sup>
                            )}
                        </div>
                    ) : (
                        <Tooltip
                            className={cx(styles.infoLabel, labelClass)}
                            title={label}
                            tooltipText={tooltipText}
                            cloudClass={cx(styles.tooltip, `py-0`)}
                            cloudStyles={{
                                width: tooltipText.length < 60 ? tooltipText.length * 8 : 300
                            }}
                            tooltipRef={tooltipRef}
                        />
                    )}
                    <span className={styles.infoError}>{error}</span>
                </div>
            )}
            {icon && (
                <div className={styles.icon}>
                    <div
                        className={cx(icon, styles.iconImg, iconClass)}
                        onClick={handleIconClick}
                    />
                </div>
            )}
            {renderInput(inputType)}
        </div>
    );
};

export default React.memo(CustomInput);
