// constants
import { darkContainer } from 'src/shared/styles/colors';

const styles = {
    container: `w-full h-screen ${darkContainer}`,
    header: `flex items-center justify-between 
        p-4 text-lg font-semibold border-b-2 border-slate-800`,
    search: `text-black [&&]:w-[180px] [&&]:p-0`,
    searchInput: `[&&]:h-[36px] [&&]:min-h-[36px]`,
    main: `px-4 py-8 h-[calc(100%_-_120px)] 
        overflow-hidden overflow-y-auto `,
    footer: `absolute bottom-0 left-0 right-0 p-4 border-t-2 border-slate-800
        flex items-center justify-between    
    `,
    footerValue: 'font-semibold mx-1',
    arrows: `flex items-center`,
    arrowsCta: `mx-1 cursor-pointer text-cyan-400 text-2xl`
};

export default styles;
