import React, { useEffect, useState } from 'react';
import cx from 'classnames';

//styles
import styles from './FilesListingModal.module.scss';

//apis
import { useLazyFetchContentQuery } from 'src/redux/queries/Org';

//widgets
import { ColorRing } from 'react-loader-spinner';
import { ICON_ADD } from 'src/shared/constants/icons';
import { Checkbox, CustomButton } from 'src/widgets';
import { toast } from 'react-toastify';

//defs
import type { IFile } from 'src/defs';

interface IProps {
    orgCode: string;
    closeModal: VoidFunction;
    handleFileSelect: (files: IFile[]) => void;
    files: IFile[];
    containerClass?: string;
}

interface IFileContent {
    fileData: {
        name: string;
        url: string;
    };
}

const FilesListingModal = ({
    orgCode,
    files,
    containerClass,
    closeModal,
    handleFileSelect
}: IProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [content, setContent] = useState<IFileContent[]>([]);
    const [selectedFilesIndex, setSelectedFilesIndex] = useState<number[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);

    const [fetchContent] = useLazyFetchContentQuery();

    useEffect(() => {
        if (orgCode) fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgCode]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const contentData = await fetchContent(orgCode).unwrap();
            setContent(contentData.data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheck = (key: number) => {
        let pos = selectedFilesIndex.indexOf(key);

        if (pos >= 0) {
            selectedFilesIndex.splice(pos, 1);
            selectedFiles.splice(pos, 1);
        } else {
            if (files?.length + selectedFilesIndex.length >= 5) {
                toast.warning('Can select maximum 5 files');
                return;
            }

            selectedFilesIndex.push(key);
            selectedFiles.push(content[key].fileData);
        }

        setSelectedFilesIndex([...selectedFilesIndex]);
        setSelectedFiles([...selectedFiles]);
    };

    const handleSubmit = () => {
        let updatedFiles = [...selectedFiles];
        if (files?.length) {
            updatedFiles = [...files, ...updatedFiles];
        }
        handleFileSelect(updatedFiles);
        closeModal();
    };

    const renderLoader = () => {
        return (
            <div className={cx('h-40 p-4 flex flex-col items-center justify-center')}>
                <ColorRing
                    height={40}
                    width={40}
                    colors={['#004170', '#004170', '#004170', '#004170', '#004170']}
                    ariaLabel="loading"
                    visible={true}
                    wrapperClass={cx()}
                />
                <p className={'text-neutral-600'}>{'Fetching content ....'}</p>
            </div>
        );
    };

    const renderContentData = () => {
        if (!content?.length)
            return <div className="text-center pt-20 h-40 font-bold">No content found!</div>;

        return (
            <div className={cx('px-2 mt-2')}>
                <div className={cx('flex items-center', 'font-medium', 'border-b-2')}>
                    <div className={cx('w-20')}></div>
                    <div className={cx('w-full')}>Name</div>
                </div>

                <div className={cx('')}>
                    {content.map((data: { fileData: { name: string } }, key) => {
                        return (
                            <div
                                key={key}
                                className={cx('flex items-center ', 'border-b-2', 'py-1')}
                            >
                                <div className={cx('w-20', 'flex items-center')}>
                                    <Checkbox
                                        isChecked={selectedFilesIndex.includes(key)}
                                        handleCheck={() => handleCheck(key)}
                                    />{' '}
                                    {key + 1}
                                </div>

                                <div className={cx('w-full')}>{data?.fileData?.name}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div
            className={cx(
                styles.container,
                'h-full',
                'p-4',
                'shadow-md',
                containerClass,
                'text-black'
            )}
        >
            <h1
                className={cx(
                    'relative',
                    'mb-2',
                    'text-lg font-bold',
                    'flex justify-between items-center'
                )}
            >
                {'Existing Org Files'}
                <div
                    className={cx(ICON_ADD, styles.containerClose, 'rounded-xl')}
                    onClick={closeModal}
                />
            </h1>
            <div className={cx(styles.files)}>
                {isLoading ? renderLoader() : renderContentData()}
            </div>

            {!!content?.length && (
                <div className={cx('m-2 mt-4')}>
                    <CustomButton
                        title={'Submit'}
                        onClick={handleSubmit}
                        className={cx('m-auto')}
                    />
                </div>
            )}
        </div>
    );
};

export default React.memo(FilesListingModal);
