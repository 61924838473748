import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

//widgets
import Footer from 'src/components/Footer';

//constants
import { ROUTES } from 'src/shared/constants/routes';

//helpers
import { analytics } from 'src/shared/utils/Analytics';

//views
import {
    Login,
    LoginSuccess,
    Signup,
    Privacy,
    Tnc,
    ForgotPassword,
    ResetPassword
} from 'src/views';

const PublicRoutes = () => {
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname + location.search;
        analytics.sendPageview(path);
    }, [location]);

    return (
        <>
            <Routes>
                <Route path={ROUTES.LOGIN} element={<Login />} />
                <Route path={ROUTES.SIGNUP} element={<Signup />} />
                <Route path={ROUTES.LOGIN_SUCCESS} element={<LoginSuccess />} />
                <Route path={ROUTES.PRIVACY} element={<Privacy />} />
                <Route path={ROUTES.TNC} element={<Tnc />} />
                <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
                <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
                <Route path="*" element={<Navigate replace to={ROUTES.LOGIN} />} />
            </Routes>
            <Footer />
        </>
    );
};

export default PublicRoutes;
