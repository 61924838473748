import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

let token: string = '';

export const apiController = new AbortController();

export const mappingApi = createApi({
    reducerPath: 'mappingApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/mapping',
        timeout: 60000,
        prepareHeaders: (headers, { getState }) => {
            // Specify RootState to ensure proper typing
            const state = getState() as any;
            token = state.user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        contactsMapping: builder.mutation<void, { file: File; orgId: string }>({
            query: (payload: { file: File; orgId: string }) => {
                const { file, orgId } = payload;

                const formData = new FormData();
                formData.append('file', file);
                formData.append('orgId', orgId);

                return {
                    method: 'POST',
                    headers: {},
                    url: `/contact`,
                    body: formData,
                    signal: apiController.signal
                };
            }
        }),
        stopContactsMapping: builder.mutation<void, void>({
            query: () => {
                return {
                    method: 'POST',
                    headers: {},
                    url: `/cancel`
                };
            }
        })
    })
});

// Exporting hooks generated by the API
export const { useContactsMappingMutation, useStopContactsMappingMutation } = mappingApi;
