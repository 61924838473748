import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true'
};

let token = '';

export const orgApi = createApi({
    reducerPath: 'orgApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/org',
        prepareHeaders: (headers, { getState }) => {
            const state = getState() as any;
            token = state.user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getAllUserOrgs: builder.query<any, any>({
            query: () => {
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `/all`
                };
            }
        }),
        getUserOrg: builder.query<any, string>({
            query: (id: string) => {
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `/${id}`
                };
            }
        }),
        addUserToOrg: builder.mutation<any, any>({
            query: (payload) => ({
                method: 'Post',
                headers: { ...headers },
                url: '/member',
                body: payload
            })
        }),
        addOrg: builder.mutation<any, any>({
            query: (payload) => ({
                method: 'Post',
                headers: { ...headers },
                url: '/add',
                body: payload
            })
        }),
        updateOrg: builder.mutation<any, any>({
            query: (payload) => {
                const { code: id } = payload;
                return {
                    method: 'PATCH',
                    url: `/${id}`,
                    body: payload
                };
            }
        }),
        updateOrgUserRole: builder.mutation<any, any>({
            query: (payload) => {
                return {
                    method: 'Post',
                    url: `/update-role`,
                    body: payload
                };
            }
        }),
        removeMember: builder.mutation<any, any>({
            query: (payload) => {
                return {
                    method: 'Post',
                    url: `/remove-member`,
                    body: payload
                };
            }
        }),
        fetchContent: builder.query<any, void | string>({
            query: (id?: string) => ({
                method: 'Get',
                url: `/fetchContent/${id}`
            })
        })
    })
});

export const {
    useLazyGetAllUserOrgsQuery,
    useLazyGetUserOrgQuery,
    useAddUserToOrgMutation,
    useAddOrgMutation,
    useUpdateOrgMutation,
    useUpdateOrgUserRoleMutation,
    useRemoveMemberMutation,
    useLazyFetchContentQuery
} = orgApi;
