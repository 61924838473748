import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { BrowserRouter as Routers } from 'react-router-dom';
import { RootState } from 'src/redux/store';

//routes
import PrivateRoutes from 'src/routes/Private';
import PublicRoutes from 'src/routes/Public';

//constants
import { USER_TYPES } from 'src/shared/constants';

//analytics
import { analytics } from 'src/shared/utils/Analytics';

const Routes = () => {
    const token = useSelector((state: RootState) => state.user.token);
    const userDetails = useSelector((state: RootState) => state.user.userDetails);

    const isAdmin = userDetails.userType === USER_TYPES.ADMIN;

    useEffect(() => {
        analytics.init();
    }, [userDetails?.id]);

    return <Routers>{token ? <PrivateRoutes isAdmin={isAdmin} /> : <PublicRoutes />}</Routers>;
};

export default Routes;
