import CryptoJS from 'crypto-js';

export const generateFilePayload = (payload: {
    files: any[];
    filePath: string | Blob;
    file: string | Blob;
    type: string | Blob;
    value: any;
    fileSession: string | Blob;
    fileName: string | Blob;
    contentType: string | Blob;
    tone: string | Blob;
    outlook: string | Blob;
    audience: string | Blob;
    regenerateCount: string | Blob;
    tokenCount: string | Blob;
    mType: string | Blob;
    title: string | Blob;
    campaignId: string | Blob;
}) => {
    const formData = new FormData();
    if (payload?.files) {
        payload.files.forEach((file: string | Blob, idx: number) => {
            if (file instanceof File) {
                formData.append(`file${idx + 1}`, file);
            }
        });

        let urls = payload.files?.filter((file: { url: any }) => typeof file.url === 'string');
        if (urls?.length) formData.append('urls', JSON.stringify(urls));
    }
    payload?.filePath && formData.append('path', payload.filePath);
    payload?.file && formData.append('file', payload.file);
    payload?.type && formData.append('type', payload?.type);
    payload?.value && formData.append('value', JSON.stringify(payload?.value));
    payload?.fileSession && formData.append('fileSession', payload?.fileSession);
    payload?.fileName && formData.append('fileName', payload?.fileName);
    payload?.contentType && formData.append('contentType', payload?.contentType);
    payload?.tone && formData.append('tone', payload?.tone);
    payload?.outlook && formData.append('outlook', payload?.outlook);
    payload?.audience && formData.append('audience', payload?.audience);
    payload?.regenerateCount && formData.append('regenerateCount', payload?.regenerateCount);
    payload?.tokenCount && formData.append('tokenCount', payload?.tokenCount);
    payload?.mType && formData.append('mType', payload?.mType);
    payload?.title && formData.append('title', payload?.title);
    payload?.campaignId && formData.append('campaignId', payload?.campaignId);
    return formData;
};

export const encryptPayload = (payload: string, token: string) => {
    const secretPass = `Bearer ${token}`;
    payload = JSON.stringify(payload);
    const encryptedPayload = CryptoJS.AES.encrypt(JSON.stringify(payload), secretPass).toString();
    return { data: encryptedPayload };
};

export const decryptPayload = (value: any, token: any) => {
    const secretPass = `Bearer ${token}`;
    const bytes = CryptoJS.AES.decrypt(value, secretPass);
    const decryptData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    const payload = JSON.parse(decryptData);
    return payload;
};

export const adjustTooltipPosition = (ref: { current: any }) => {
    if (ref?.current) {
        const tooltipRect = ref.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        const spaceTop = tooltipRect.top;
        const spaceBottom = viewportHeight - tooltipRect.bottom;
        const spaceLeft = tooltipRect.left;
        const spaceRight = viewportWidth - tooltipRect.right;

        let cloudStyle = {};
        let tipStyle = {};
        if (spaceTop < tooltipRect.height && spaceBottom >= tooltipRect.height) {
            cloudStyle = {
                left: '-100%',
                bottom: 0,
                transform: `translateY(112%)`
            };

            tipStyle = {
                top: 0,
                transform: `rotate(180deg) translateY(100%)`
            };
        } else if (spaceTop >= tooltipRect.height && spaceBottom < tooltipRect.height) {
            cloudStyle = {
                bottom: `translateY(-110%)`,
                left: '-100%'
            };
        } else if (spaceLeft < tooltipRect.width && spaceRight >= tooltipRect.width) {
            cloudStyle = {
                bottom: '152%',
                left: '-100%',
                color: 'red'
            };
        } else if (spaceLeft >= tooltipRect.width && spaceRight < tooltipRect.width) {
            cloudStyle = {
                bottom: '152%'
            };
        }

        return { cloudStyle, tipStyle };
    }
};

export const formatSubType = (input?: string) => {
    if (!input?.length) return '';

    const capitalized = input.replace(/([a-z])([A-Z])/g, '$1 $2');
    const parts = capitalized.split('_');
    let label = '';

    for (let i = 1; i < parts.length; i++) {
        label += parts[i].charAt(0).toUpperCase() + parts[i].slice(1) + ' ';
    }

    label = label.trim();

    return { label: label, value: input };
};

export const sanitizeFileNames = (fileName: string) => {
    let name = decodeURI(fileName) || '';
    return name;
};

export const addToOptions = (
    options: { label: string; value: string }[] | [],
    data: { label: string; value: string } | undefined | null
): { label: string; value: string }[] | [] => {
    if (!data || !Object.keys(data)?.length) {
        return options;
    }

    if (!options?.length) return [data];

    let isExisting = options.find((opt) => opt.value === data.value || opt.label === data.label);
    let sortedOptions = [...options].sort((a, b) => a.value.localeCompare(b.value));

    if (isExisting) return sortedOptions;

    return [data, ...sortedOptions];
};

export const formatMarkdown = (inputString: string) => {
    if (!inputString) return '';

    // Convert headers
    let formattedString = inputString.replace(/^# (.*)$/gm, '$1');
    formattedString = formattedString.replace(/^## (.*)$/gm, '$1');
    formattedString = formattedString.replace(/^### (.*)$/gm, '$1');
    formattedString = formattedString.replace(/^#### (.*)$/gm, '$1');
    formattedString = formattedString.replace(/^##### (.*)$/gm, '$1');
    formattedString = formattedString.replace(/^###### (.*)$/gm, '$1');

    // Convert bold text
    formattedString = formattedString.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Convert italic text
    formattedString = formattedString.replace(/\*(.*?)\*/g, '<em>$1</em>');

    return formattedString.replaceAll('\n', '<br />');
};

export const removeHTMLTags = (str: string) => {
    if (str === null || str === undefined) return str;
    return str.replace(/<\/?[^>]+(>|$)/g, '');
};

export const debounce = (callback: (...params: any[]) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout | null = null;

    // Define and return the debounced function
    return function (this: any, ...args: any[]) {
        const context = this;

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            callback.apply(context, args);
        }, delay);
    };
};

export function deepClone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export const openUrl = (url: string) => window.open(url, '_self');

export function sanitizePayloadArray(payloadArray: any) {
    return payloadArray.map((payload: any) => {
        const sanitizedPayload: any = {};

        Object.keys(payload).forEach((key) => {
            const value = payload[key];

            // Check if the value is non-empty, non-null, non-undefined, and non-falsey
            if (
                value !== null &&
                value !== undefined &&
                (typeof value !== 'string' || value.trim() !== '') &&
                (typeof value !== 'object' ||
                    (typeof value === 'object' && Object.keys(value).length > 0)) // Handle non-empty objects
            ) {
                sanitizedPayload[key] = value;
            }
        });

        return sanitizedPayload;
    });
}

export function format(template: string, ...args: string[]): string {
    return template.replace(/{(\d+)}/g, (match, index) => {
        return args[index] ? args[index] : '';
    });
}

export function toCapitalizedWords(str: string) {
    return str
        .replace(/([A-Z])/g, ' $1') // Add space before each uppercase letter
        .replace(/^./, (firstChar) => firstChar.toUpperCase()); // Capitalize the first letter
}

export function formatSnakeString(snakeCaseString: string): string {
    return snakeCaseString
        .split('_') // Split the string by underscores
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(' '); // Join the words with spaces
}
