import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// styles
import styles from './styles';

// apis
import { useLazyGetCompaniesQuery } from 'src/redux/queries/Admin/Companies';

// helpers
import { startLoading, stopLoading } from 'src/redux/reducers';

// widgets
import CompanyList from './widgets/CompanyList';

// defs
import type { ICompany } from 'src/defs';
import { ICON_ARROW_LEFT, ICON_ARROW_RIGHT } from 'src/shared/constants/icons';
import { CustomInput } from 'src/widgets';
import { debounce } from 'src/shared/utils';

const Companies = () => {
    const [getCompanies] = useLazyGetCompaniesQuery();

    const [query, setQuery] = useState('');
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(1000);
    const [searchQuery, setSearchQuery] = useState('');
    const [companies, setCompanies] = useState<ICompany[]>([]);
    const [totalCompanies, setTotalCompanies] = useState(0);
    const [totalPages, setTotalPages] = useState(1);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchLeads();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset, searchQuery]);

    const fetchLeads = async () => {
        try {
            dispatch(startLoading());
            await getCompanies({ searchQuery, limit, offset })
                .unwrap()
                .then((data) => {
                    console.log('data >>> ', data);
                    setCompanies(data.companies);
                    setTotalCompanies(data.total);
                    setTotalPages(Math.ceil(data.total / limit) || 1);
                })
                .catch((err) => {
                    console.log(err);
                    throw err;
                });
        } catch (error: any) {
            toast.error(error?.data?.msg || 'Not able to fetch companies!');
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleRightArrow = () => {
        if (offset + 1 >= totalPages) return;
        setOffset((state) => state + 1);
    };

    const handleLeftArrow = () => {
        if (offset === 0) return;
        setOffset((state) => state - 1);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearch = useCallback(
        debounce(async (query) => {
            try {
                if (query && query?.length < 3) return;

                setSearchQuery(query);
            } catch (e: any) {
                console.log(e);
                toast.error(e, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }, 1000),
        []
    );

    const handleSearch = (e: { target: { value: string } }) => {
        const value = e.target.value;
        setQuery(value);
        debounceSearch(value);
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div>{'Companies'}</div>
                <CustomInput
                    className={styles.search}
                    inputClass={styles.searchInput}
                    value={query}
                    onChange={handleSearch}
                    inputProps={{ placeholder: 'Search' }}
                />
            </div>
            <div className={styles.main}>
                <CompanyList companies={companies} offset={offset} limit={limit} />
            </div>
            <div className={styles.footer}>
                <div className="flex">
                    <div className={styles.footerValue}>{'Page : '}</div>
                    <div className={styles.footerValue}>{offset + 1}</div>
                    <div className={styles.footerValue}>{' of '}</div>
                    <div className={styles.footerValue}>{totalPages}</div>
                </div>

                <div className={styles.arrows}>
                    <div
                        className={cx(ICON_ARROW_LEFT, styles.arrowsCta)}
                        onClick={handleLeftArrow}
                    />
                    <div className={styles.footerValue}> </div>
                    <div
                        className={cx(ICON_ARROW_RIGHT, styles.arrowsCta)}
                        onClick={handleRightArrow}
                    />
                </div>

                <div>
                    <span>{'Companies : '}</span>
                    <span className={styles.footerValue}>{totalCompanies}</span>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Companies);
