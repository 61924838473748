//libs
import React, { useEffect, useState } from 'react';
import cx from 'classnames';

//defs
import type { RootState } from 'src/redux/store';

//widgets
import PersonaForm from './widgets/PersonaForm';
import PersonaCard from './widgets/PersonaCard';
import { useDispatch, useSelector } from 'react-redux';
import {
    useAddAudienceMutation,
    useLazyGetAudienceSegmentsQuery,
    useUpdateAudienceMutation
} from 'src/redux/queries/Audience';
import { toast } from 'react-toastify';
import { updateSegments } from 'src/redux/reducers/taxonomy';
import { startLoading, stopLoading } from 'src/redux/reducers';

interface IProps {
    data: any;
    audiences: [];
    updateData: (data: any) => void;
    handleSelectAudience: (data: any) => void;
}

const Personalized = ({
    data: campaignData,
    audiences,
    handleSelectAudience,
    updateData
}: IProps) => {
    const user = useSelector((state: RootState) => state.user);
    const userDetails = user.userDetails;
    const isDark = user.isDarkTheme;

    const taxonomySegments = useSelector((state: any) => state.taxonomy.segments) || [];

    const dispatch = useDispatch();

    const [audienceData, setAudienceData] = useState<any[]>(audiences);
    const [editAudienceData, setEditAudienceData] = useState<any[]>([]);
    const [showEditAudiencePersona, setShowEditAudiencePersona] = useState<boolean>(true);
    const [segmentOptions, setSegmentOptions] = useState<{ label: string; value: string }[]>([]);

    const [addNewAudience] = useAddAudienceMutation();
    const [updateAudience] = useUpdateAudienceMutation();
    const [getAudienceSegments] = useLazyGetAudienceSegmentsQuery();

    useEffect(() => {
        if (taxonomySegments?.length) {
            setSegmentOptions(taxonomySegments);
        } else {
            fetchSegments();
        }
        return () => {
            setSegmentOptions([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (audienceData?.length) {
            setShowEditAudiencePersona(false);
        }
    }, [audienceData]);

    const fetchSegments = async () => {
        try {
            await getAudienceSegments(null)
                .unwrap()
                .then((data) => {
                    if (data?.data?.length) {
                        let options = data?.data?.map((d: string) => ({ label: d, value: d }));
                        setSegmentOptions(options);
                        dispatch(updateSegments(data.data));
                    }
                });
        } catch (err) {
            console.log(err);
        }
    };

    const handleAudienceSave = async (audiencePersona: any) => {
        try {
            dispatch(startLoading());

            let savedAudiencePersona = audiencePersona;

            const payload = {
                ...audiencePersona,
                ownerId: userDetails?.id,
                ownerOrgId: campaignData?.orgCode,
                type: 'personalized'
            };

            if (!audiencePersona._id) {
                await addNewAudience(payload)
                    .unwrap()
                    .then((res) => {
                        updateData(res?.data?.audience);
                        savedAudiencePersona = res?.data?.audience;
                        toast.success(res.message);
                    })
                    .catch((err) => {
                        console.log('err >>>> ', err);
                        toast.error(err?.data?.message);
                    });
            } else {
                const { _id, ...rest } = payload;
                await updateAudience({ payload: rest, id: _id })
                    .unwrap()
                    .then((res) => {
                        updateData(audiencePersona);
                        toast.success(res.message);
                    })
                    .catch((err) => {
                        console.log('err >>>> ', err);
                        toast.error(err?.data?.message);
                    });
            }

            let pos = audienceData.findIndex((audi) => audi._id === savedAudiencePersona._id);

            if (Object.keys(savedAudiencePersona)?.length) {
                if (pos !== -1) {
                    audienceData.splice(pos, 1, savedAudiencePersona);
                    setAudienceData([...audienceData]);
                } else {
                    setAudienceData((audience) => [...audience, savedAudiencePersona]);
                }
            }

            setShowEditAudiencePersona(false);
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleEditPersonaCard = (audiencePersona: any) => {
        setEditAudienceData(audiencePersona);
        setShowEditAudiencePersona(true);
    };

    const handleSelect = async (audiencePersona: any) => {
        try {
            let pos = audienceData.findIndex((audi) => audi._id === audiencePersona._id);

            if (Object.keys(audiencePersona)?.length) {
                if (pos !== -1) {
                    audienceData.splice(pos, 1, audiencePersona);
                    setAudienceData([...audienceData]);
                } else {
                    setAudienceData((audience) => [...audience, audiencePersona]);
                }
            }

            handleSelectAudience(audiencePersona);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className={cx('flex-1', 'p-5')}>
            {(!audienceData?.length || showEditAudiencePersona) && (
                <PersonaForm
                    data={editAudienceData}
                    onSave={handleAudienceSave}
                    onCancel={() => setShowEditAudiencePersona(false)}
                    segments={segmentOptions}
                    isDark={isDark}
                />
            )}

            {!!audienceData?.length && !showEditAudiencePersona && (
                <PersonaCard
                    data={audienceData}
                    audiences={campaignData?.audience}
                    handleEditPersonaCard={handleEditPersonaCard}
                    handleSelectAudience={handleSelect}
                    isDark={isDark}
                />
            )}
        </div>
    );
};

export default React.memo(Personalized);
