import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

// styles
import styles from './styles';

// apis
import {
    useLazyGetCompanyQuery,
    useUpdateCompanyMutation
} from 'src/redux/queries/Admin/Companies';

// helpers
import { startLoading, stopLoading } from 'src/redux/reducers';
import { formatSnakeString } from 'src/shared/utils';

// widgets
import { CustomButton, CustomInput } from 'src/widgets';
import useCustomNavigation from 'src/hooks/useCustomNavigations';
import { ADMIN_ROUTES } from 'src/shared/constants';

interface IResponse {
    [key: string]: string;
}

const Company = () => {
    const [getCompany] = useLazyGetCompanyQuery();
    const [updateCompany] = useUpdateCompanyMutation();

    const [company, setCompany] = useState<IResponse>({});
    const [fields, setFields] = useState<string[]>([]);

    const handleNavigation = useCustomNavigation();
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        if (id) fetchLeads(id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const fetchLeads = async (id: string) => {
        try {
            dispatch(startLoading());
            await getCompany(id)
                .unwrap()
                .then((data) => {
                    console.log('data >>> ', data);
                    const { _id, created_at, updated_at, ...rest } = data.data;
                    setCompany(rest);
                    setFields(Object.keys(rest));
                })
                .catch((err) => {
                    console.log(err);
                    throw err;
                });
        } catch (error: any) {
            toast.error(error?.data?.msg || 'Not able to fetch company!');
        } finally {
            dispatch(stopLoading());
        }
    };

    const renderInfo = () => {
        if (fields?.length)
            return fields.map((field, idx) => {
                return (
                    <CustomInput
                        key={field}
                        label={formatSnakeString(field)}
                        value={company[field] || ''}
                        onChange={handleChange}
                        id={field}
                        inputType={'textarea'}
                        className={styles.inputContainer}
                        inputClass={styles.input}
                    />
                );
            });
    };

    console.log('fields >>> ', fields, company);

    // Handle input changes dynamically
    const handleChange = (e: { target: { id: string; value: string } }) => {
        const { id, value } = e.target;
        console.log('name >>>> ', id, value);
        setCompany((prevState) => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSave = async () => {
        try {
            if (!id) return;

            dispatch(startLoading());

            await updateCompany({ id, payload: company })
                .unwrap()
                .then((res: any) => {
                    console.log('response>>> ', res);
                    toast.success(res.message);
                });
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(stopLoading());
        }
    };

    const addNewField = () => {
        let pos = 10;
        let newFields = [...fields, 'new_field'];
        setCompany((state) => ({ ...state, new_field: '' }));
        setFields([...newFields]);
    };

    const removeField = (id: string) => {
        let updatedFields = [...fields];
        let pos = updatedFields.indexOf(id);

        if (pos !== -1) {
            updatedFields.splice(pos, 1);
            setFields([...updatedFields]);
        }
    };

    const navigateToCompanies = () => {
        handleNavigation(ADMIN_ROUTES.COMPANIES);
    };

    const renderModal = () => {
        return <div className={`absolute`}></div>;
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div>{'Company Details'}</div>
                <div className={styles.headerCta} onClick={navigateToCompanies}>
                    {'Back'}
                </div>
            </div>
            <div className={styles.main}>
                <div className={styles.form}>{renderInfo()}</div>
                {/* <div className={styles.buttons}>
                    <CustomButton title={'Add New Field'} onClick={addNewField} isDark />
                    <CustomButton title={'Save'} onClick={handleSave} isDark />
                </div> */}
            </div>
        </div>
    );
};

export default React.memo(Company);
