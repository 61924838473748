import React from 'react';
import { generatePath } from 'react-router-dom';

// styles
import styles from './styles';

// defs
import type { ICompany } from 'src/defs';

// hooks
import useCustomNavigation from 'src/hooks/useCustomNavigations';

// constants
import { ADMIN_ROUTES } from 'src/shared/constants';

interface IProps {
    companies: ICompany[];
    offset: number;
    limit: number;
}

const CompanyList = ({ companies, limit, offset }: IProps) => {
    const handleNavigation = useCustomNavigation();

    const handleClick = (id: string) => {
        const path = generatePath(ADMIN_ROUTES.COMPANY, { id });
        handleNavigation(path);
    };

    return (
        <div className={styles.container}>
            {companies.map((company, key) => (
                <div className={styles.card} key={key} onClick={() => handleClick(company._id)}>
                    <div style={{ minWidth: 40 }} className={styles.cardCount}>
                        {limit * offset + (key + 1)}
                    </div>
                    <div className={styles.cardTitle}>{company.company_name}</div>
                </div>
            ))}
        </div>
    );
};

export default React.memo(CompanyList);
