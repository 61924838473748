import React, { useState } from 'react';
import cx from 'classnames';

//widgets
import { CustomButton, CustomInput } from 'src/widgets';
import { toast } from 'react-toastify';

//constants
import { CANCEL, DESCRIPTION, NAME, PRESET_DESCRIPTION, SAVE, TITLE, TONE } from './constants';
import { CONTENT_FILTERS, EMOTIONAL_FILTERS, PERSONA_FILTERS } from '../Sidebar/constants';
import { STRINGS } from 'src/shared/strings';

//defs
import type { IContentFormData } from 'src/defs';
import { useDispatch } from 'react-redux';

//api
import { useAddPresetMutation } from 'src/redux/queries/Presets';

//helpers
import { startLoading, stopLoading } from 'src/redux/reducers';

interface IProps {
    data: IContentFormData;
    handleClose: VoidFunction;
}

const overlay = cx(
    'absolute inset-0 z-50',
    'bg-black bg-opacity-50 text-black',
    'flex items-center justify-between'
);

const containerClass = cx('bg-white', 'py-4 px-8 m-auto', 'rounded shadow');

const PresetsModal = ({ data, handleClose }: IProps) => {
    const [presetName, setPresetName] = useState('');
    const [presetDescription, setPresetDescription] = useState('');

    const dispatch = useDispatch();

    const [addPreset] = useAddPresetMutation();

    const handleSavePreset = async () => {
        if (!presetName) {
            toast.error('Please add preset name');
            return;
        }

        try {
            dispatch(startLoading());
            let res = await addPreset({
                orgId: data.orgCode,
                presetName: presetName,
                presetDesc: presetDescription,
                tone: data.toneRegister,
                emotionalFilter: data.emotionalFilter,
                contentFilter: data.contentFilter,
                personaFilter: data.personaFilter,
                bdrType: data.subType
                // audience: data.audience
            }).unwrap();

            toast.success(res?.message, { toastId: '1' });
            handleClose();
        } catch (error: any) {
            toast.error(error?.data?.message || 'Failed to save the preset!', { toastId: '2' });
        } finally {
            dispatch(stopLoading());
        }
    };

    const renderSelectedPresets = (title: string, value: string) => {
        return (
            <div className={'mt-1'}>
                <div className={''}>{title}</div>
                <CustomInput value={value} onChange={undefined} readonly />
            </div>
        );
    };

    return (
        <div className={overlay}>
            <div className={containerClass}>
                <div className={cx('font-semibold text-lg')}>{TITLE}</div>
                <div className={cx('text-sm')}>{PRESET_DESCRIPTION}</div>

                <div className={'mt-3'}>{NAME}</div>
                <CustomInput
                    value={presetName}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                        setPresetName(e.target.value)
                    }
                />

                <div className={'mt-1'}>{DESCRIPTION}</div>
                <CustomInput
                    value={presetDescription}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                        setPresetDescription(e.target.value)
                    }
                />

                <div className={cx('mt-1', 'grid grid-cols-2 gap-x-8')}>
                    {renderSelectedPresets(TONE, data.toneRegister)}
                    {renderSelectedPresets(EMOTIONAL_FILTERS, data.emotionalFilter)}
                    {renderSelectedPresets(CONTENT_FILTERS, data.contentFilter)}
                    {renderSelectedPresets(PERSONA_FILTERS, data.personaFilter)}
                    {renderSelectedPresets(STRINGS.BDR_TYPE, data.subType?.label)}
                </div>

                <div className={cx('mt-3 flex items-center justify-end')}>
                    <CustomButton title={CANCEL} onClick={handleClose} className="mr-3" />
                    <CustomButton title={SAVE} onClick={handleSavePreset} />
                </div>
            </div>
        </div>
    );
};

export default React.memo(PresetsModal);
