import React, { useEffect, useState, MouseEvent } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';

// styles
import styles from '../../ContactTable.module.scss';

// widgets
import Heading from '../Heading';
import DeleteModal from 'src/components/DeleteModal';
import { CustomInput } from 'src/widgets';

// defs
import type { IContacts } from 'src/defs';
import type { IList } from '../Heading/Headings';

// constants
import { CONTACT_KEYS, SINGLE_LINE_KEYS } from '../../constants';
import { ICON_CANCEL, ICON_PENCIL, ICON_SAVE } from 'src/shared/constants/icons';

// helpers
import { maskEmail } from '../../helpers';

interface IProps {
    list: IContacts[];
    className?: string;
    contactsQuery: { offset: number; limit: number };
    selectedContacts: IContacts[];
    priorityList: IList[];
    isCampaign?: boolean;
    handleOrderChange: (data: { order: number; sortBy: string }) => void;
    handleSelectContacts: (contacts: IContacts[]) => void;
    handleUpdateContact: (contacts: { [key: string]: string }) => Promise<void>;
    handlePriorityUpdate: (data: IList, idx: number) => void;
}

const tableDataHeadingStyle = cx(
    'bg-white',
    'flex items-center',
    'py-2 mx-4 text-justify w-[240px]',
    styles.data
);
const tableDataRowStyles = cx(
    'my-6 mx-4 text-justify w-[240px]',
    'cursor-pointer',
    styles.data,
    styles.colData
);
const rowStyle = cx('flex flex-1');

const nameStyles = cx('[&&]:w-[132px] [&&]:min-w-[132px]');

const TableData = ({
    list,
    className,
    contactsQuery,
    selectedContacts,
    priorityList,
    isCampaign,
    handleOrderChange,
    handleSelectContacts,
    handleUpdateContact,
    handlePriorityUpdate
}: IProps) => {
    const [checkedContacts, setCheckedContacts] = useState<IContacts[]>([]);
    const [isEditing, setIsEditing] = useState<number | null>(null);

    const [contactsData, setContactsData] = useState<IContacts[]>([]);
    const [updatedData, setUpdatedData] = useState<{ [key: string]: string }>({});
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const [priorityKeys, setPriorityKeys] = useState(() => {
        return CONTACT_KEYS.map((key) => key);
    });

    const tableRowRefs = Array(contactsData.length).fill(React.createRef());

    useEffect(() => {
        setContactsData(list);
    }, [list]);

    useEffect(() => {
        setCheckedContacts([...selectedContacts]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContacts]);

    const handleRowClick = (e: any) => {
        if (isEditing !== null) return;

        if (e.target?.classList?.contains(styles.colData)) {
            e.target?.classList?.remove(styles.colData);
        } else {
            e.target?.classList?.add(styles.colData);
        }
    };

    const hanldeCheckBoxSelect = (value: string) => {
        if (value === 'all') {
            if (checkedContacts.length === contactsData.length) {
                setCheckedContacts([]);
                handleSelectContacts([]);
            } else {
                setCheckedContacts(() => contactsData.map((contact) => contact));
                handleSelectContacts(contactsData.map((contact) => contact));
            }
        } else {
            let pos = checkedContacts.findIndex((contact) => contact._id === value);
            if (pos !== -1) {
                let CC = checkedContacts.filter((_, index) => index !== pos);
                setCheckedContacts(CC);
                handleSelectContacts(CC);
            } else {
                let contact = contactsData.find((c) => c._id === value);
                if (contact) {
                    setCheckedContacts([...checkedContacts, contact]);
                    handleSelectContacts([...checkedContacts, contact]);
                }
            }
        }
    };

    const handleEditContact = (idx: number, value: string, key: string) => {
        const updatedContact = contactsData.map((data) => data);
        updatedContact.splice(idx, 1, { ...contactsData[idx], [key]: value });
        setContactsData(() => updatedContact);

        setUpdatedData((state) => ({ id: contactsData[idx]._id, ...state, [key]: value }));
    };

    const handleSave = async (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!Object.keys(updatedData)?.length) {
            toast.warning('Please make some changes to update the contact information.');
            return;
        }

        setShowUpdateModal(true);
    };

    const handleUpdate = async () => {
        setShowUpdateModal(false);
        await handleUpdateContact(updatedData);
        setIsEditing(null);
        setUpdatedData({});
    };

    const handleEdit = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const id = Number(e.currentTarget.id);

        if (isEditing !== id) {
            setUpdatedData({});
            setContactsData(() => list);
            setIsEditing(id);
        }
    };

    const handleCancel = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsEditing(null);
        setUpdatedData({});
        setContactsData(() => list);
    };

    const renderInput = (contact: IContacts, idx: number) => {
        return (
            <div className={cx('flex')}>
                {CONTACT_KEYS.map((key, index) => {
                    const isSingleInput = SINGLE_LINE_KEYS.includes(key);
                    const isEmail = key === 'email';
                    return (
                        <CustomInput
                            key={index}
                            value={
                                (isEmail ? maskEmail(contact[key]) : contact[key] || '') as string
                            }
                            className={cx(tableDataRowStyles, '[&&]:p-0 [&&]:rounded')}
                            onChange={(e: any) => handleEditContact(idx, e.target.value, key)}
                            inputType={isSingleInput ? '' : 'textarea'}
                        />
                    );
                })}
            </div>
        );
    };

    const renderText = (contact: IContacts) => {
        return (
            <div className={cx('flex')}>
                {CONTACT_KEYS.map((key, index) => {
                    const isEmail = key === 'email';
                    return (
                        <div
                            key={index}
                            className={cx(tableDataRowStyles, isEmail && 'text-gray-500')}
                            onClick={(e) => handleRowClick(e)}
                        >
                            {isEmail ? maskEmail(contact[key]) : contact[key]}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className={styles.table}>
            <div className={cx(rowStyle, 'bg-white', 'sticky top-0 z-10', 'inline-flex')}>
                <div className={cx(tableDataRowStyles, styles.wCheck, 'flex items-center')}>
                    <input
                        type="checkbox"
                        value={contactsData.length}
                        checked={
                            !!contactsData.length && checkedContacts.length === contactsData.length
                        }
                        onChange={() => hanldeCheckBoxSelect('all')}
                        className="form-checkbox h-5 w-5 text-blue-600 cursor-pointer"
                    />
                </div>

                <div className={cx(tableDataHeadingStyle, styles.w100)}>SR.No</div>
                <div className={cx(tableDataHeadingStyle, styles.w40)} />

                <Heading
                    list={priorityList}
                    className={cx(tableDataHeadingStyle)}
                    handleOrderChange={handleOrderChange}
                    handlePriorityUpdate={handlePriorityUpdate}
                    isCampaign={isCampaign}
                />
            </div>
            <div className={cx('grid', 'my-2')}>
                {contactsData.map((contact: any, key: number) => {
                    const { offset, limit } = contactsQuery;
                    const isSelected = !!checkedContacts.find((cc) => cc._id === contact._id);
                    const bg = key % 2 === 0 ? 'bg-slate-200' : 'bg-slate-300';

                    return (
                        <div
                            key={contact._id}
                            ref={(r) => (tableRowRefs[key] = r)}
                            className={cx(rowStyle, bg)}
                        >
                            <div className={cx(tableDataRowStyles, styles.wCheck)}>
                                <input
                                    type="checkbox"
                                    value={key}
                                    checked={isSelected}
                                    onChange={() => {
                                        hanldeCheckBoxSelect(contact._id);
                                    }}
                                    className="form-checkbox h-5 w-5 text-blue-600 cursor-pointer"
                                />
                            </div>
                            <div className={cx(tableDataRowStyles, styles.w100)}>
                                {offset * limit + key + 1}
                            </div>
                            <div className="flex">
                                <div
                                    className={cx(
                                        tableDataRowStyles,
                                        styles.w40,
                                        'z-[1]',
                                        'sticky left-2'
                                    )}
                                >
                                    {isEditing === key ? (
                                        <div className="flex justify-between pt-1.5">
                                            <div
                                                className={cx(
                                                    styles.icon,
                                                    ICON_CANCEL,
                                                    `text-white [&&]:bg-slate-700`
                                                )}
                                                onClick={handleCancel}
                                            />
                                            <div
                                                className={cx(
                                                    styles.icon,
                                                    ICON_SAVE,
                                                    `text-white [&&]:bg-slate-800`
                                                )}
                                                onClick={handleSave}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className={cx(styles.icon, ICON_PENCIL, 'text-[#333]')}
                                            id={`${key}`}
                                            onClick={handleEdit}
                                        />
                                    )}
                                </div>
                                {isEditing === key
                                    ? renderInput(contact, key)
                                    : renderText(contact)}
                            </div>
                        </div>
                    );
                })}
            </div>

            {showUpdateModal && (
                <DeleteModal
                    handleDelete={handleUpdate}
                    title={'Would you like to update this contact?'}
                    desc={'Updating will replace the current contact information permanently.'}
                    setIsOpen={() => {
                        setShowUpdateModal(false);
                    }}
                    yesTitle={'Update'}
                />
            )}
        </div>
    );
};

export default React.memo(TableData);
