import React, { useEffect, useState } from 'react';
import cx from 'classnames';

// consts
import { darkContainer } from 'src/shared/styles/colors';
import { STRINGS } from 'src/shared/strings';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetPresetsQuery } from 'src/redux/queries/Presets';
import { startLoading, stopLoading } from 'src/redux/reducers';
import { RootState } from 'src/redux/store';

const containerStyle = cx(darkContainer, 'w-full py-4 px-8');
const paraHeadingStyle = cx('font-semibold mr-2');

interface IPreset {
    audience: any[];
    bdrType: { label: string; value: string; _id: string };
    contentFilter: string;
    createdAt: string;
    emotionalFilter: string;
    orgId: string;
    personaFilter: string;
    presetDesc: string;
    presetName: string;
    tone: string;
    updatedAt: string;
    userEmail: string;
    userId: string;
    _id: string;
}

const Presets = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: RootState) => state.root.isLoading);

    const [getPresets] = useLazyGetPresetsQuery();

    const [presets, setPresets] = useState<[]>([]);

    useEffect(() => {
        fetchAllPresets();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAllPresets = async () => {
        try {
            dispatch(startLoading());
            await getPresets()
                .unwrap()
                .then((data) => {
                    setPresets(data.data.presets);
                });
        } catch (Err) {
            console.log(Err);
        } finally {
            dispatch(stopLoading());
        }
    };

    console.log('presets ?>>>> ', presets);

    const [openWidgetId, setOpenWidgetId] = useState<string | null>(null);

    const toggleDetails = (id: string) => {
        setOpenWidgetId(openWidgetId === id ? null : id);
    };

    const Preset = (widget: IPreset) => {
        return (
            <div
                key={widget._id}
                className={cx(
                    'bg-slate-50',
                    'mb-4 border border-slate-400 rounded-lg overflow-hidden shadow-md'
                )}
            >
                {/* Widget Header */}
                <div
                    className="p-4 flex justify-between items-center cursor-pointer hover:bg-slate-400"
                    onClick={() => toggleDetails(widget._id)}
                >
                    <div>
                        <h2 className="text-lg font-semibold text-gray-800">{widget.presetName}</h2>
                        <p className="text-sm text-gray-600">{widget.presetDesc}</p>
                    </div>
                    <button
                        className={`text-black transform transition-transform ${
                            openWidgetId === widget._id ? 'rotate-180' : 'rotate-0'
                        }`}
                    >
                        ▼
                    </button>
                </div>

                {/* Dropdown Details */}
                {openWidgetId === widget._id && (
                    <div className="p-4 border-t bg-gray-50 text-sm text-gray-700">
                        <p className="my-2">
                            <span className={paraHeadingStyle}>Content Filter:</span>
                            {widget.contentFilter}
                        </p>
                        <p className="my-2">
                            <span className={paraHeadingStyle}>Emotional Filter:</span>
                            {widget.emotionalFilter}
                        </p>
                        <p className="my-2">
                            <span className={paraHeadingStyle}>Persona Filter:</span>
                            {widget.personaFilter}
                        </p>
                        <p className="my-2">
                            <span className={paraHeadingStyle}>Tone:</span> {widget.tone}
                        </p>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={containerStyle}>
            <h1 className={cx('mb-4')}>{STRINGS.PRESETS}</h1>

            <div>
                {!presets?.length && !isLoading && <h1>{'No Presets found!'}</h1>}

                {presets.map((preset: IPreset, key) => (
                    <Preset {...preset} key={key} />
                ))}
            </div>
        </div>
    );
};

export default React.memo(Presets);
