// constants
import { darkContainer } from 'src/shared/styles/colors';

const styles = {
    container: `w-full h-screen ${darkContainer}`,
    header: `flex justify-between items-center p-4 text-lg font-semibold border-b-2 border-slate-800`,
    headerCta: `cursor-pointer text-base`,
    main: `bg-slate-800 px-4 py-2 h-[calc(100%_-_64px)] 
        overflow-hidden overflow-y-auto`,
    form: `flex flex-wrap gap-x-8`,
    input: `bg-slate-900 [&&]:text-white resize-none`,
    inputContainer: `
        [&&]:lg:w-[calc(100%_/_2_-_16px)] 
        [&&]:md:w-[calc(100%_/_2_-_16px)]
        [&&]:sm:w-[calc(100%_/_2_-_16px)]`,
    buttons: `flex flex-1 justify-between my-2`
};

export default styles;
