import React, { ChangeEvent, KeyboardEvent, useState } from 'react';

//styles
import styles from './styles.module.scss';

//constants
import { STRINGS } from 'src/shared/strings';

interface IProps {
    values: string[];
    onChange: (value: string[]) => void;
    label?: string;
    error?: string;
}

const CustomInputSeparator = ({ values = [], onChange, label = '', error = '' }: IProps) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' || e.key === ';') {
            e.preventDefault();
            if (inputValue.trim() !== '') {
                onChange([...values, inputValue.trim()]);
            }
            setInputValue('');
        }
    };

    const handleInputBlur = () => {
        if (inputValue.trim() !== '') {
            onChange([...values, inputValue.trim()]);
            setInputValue('');
        }
    };

    const handleRemoveItem = (index: number) => {
        const updatedItems = values.slice();
        updatedItems.splice(index, 1);
        onChange(updatedItems);
    };

    return (
        <div className={styles.container}>
            {label && (
                <div className={styles.info}>
                    <label className={styles.infoLabel}>{label}</label>
                    <span className={styles.infoError}>{error}</span>
                </div>
            )}
            {values.map((item, index) => (
                <div
                    key={index}
                    className={styles.containerItem}
                    onClick={() => handleRemoveItem(index)}
                >
                    {item}
                </div>
            ))}
            <input
                className={styles.containerInput}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                onBlur={handleInputBlur}
                placeholder={STRINGS.URLS_PLACEHOLDER}
                autoFocus
            />
        </div>
    );
};

export default React.memo(CustomInputSeparator);
