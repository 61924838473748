import React from 'react';

//hooks
import useCustomNavigation from 'src/hooks/useCustomNavigations';

//constants
import { ROUTES } from 'src/shared/constants/routes';

const Footer = () => {
    const handleNavigation = useCustomNavigation();

    const gotToTerms = () => {
        handleNavigation(ROUTES.TNC);
    };

    const goToPrivacy = () => {
        handleNavigation(ROUTES.PRIVACY);
    };

    return (
        <div className={'footer flex justify-between items-center p-2 bg-slate-200'}>
            <div className="text-sm font-medium">&copy; 2024 CLAP</div>
            <div className="text-base font-medium">
                <span className="cursor-pointer" onClick={gotToTerms}>
                    Terms
                </span>
                <span className="mx-2">|</span>
                <span className="cursor-pointer" onClick={goToPrivacy}>
                    Privacy
                </span>
                <span className="mx-2">|</span>
                <span className="cursor-pointer">
                    <a href="mailto:ash@campaignlikea.pro">Contact us</a>
                </span>
            </div>
        </div>
    );
};

export default React.memo(Footer);
