import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { IContacts } from 'src/defs';

interface IDeletePayload {
    ids: string[];
}

interface IContactResponse {
    total: number;
    completionPercentage: string;
    fieldAnalytics: { field: string; completion: number; incomplete: number }[];
}

interface IContactQuery {
    recordType: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
    order?: number;
    searchQuery?: string;
    groupBy?: string;
}

const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true'
};

let token: string = '';

export const adminLeadsApi = createApi({
    reducerPath: 'adminLeadsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/admin/leads',
        timeout: 60000,
        prepareHeaders: (headers, { getState }) => {
            // Specify RootState to ensure proper typing
            const state = getState() as any;
            token = state.user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getLeads: builder.query<IContactResponse, IContactQuery>({
            query: (payload: IContactQuery) => {
                const searchString = encodeURIComponent(payload.searchQuery || '');

                return {
                    method: 'GET',
                    headers: { ...headers },
                    url: `/`,
                    params: { ...payload, searchQuery: searchString }
                };
            }
        }),
        getLeadsAnalytics: builder.query<IContactResponse, void>({
            query: () => {
                return {
                    method: 'GET',
                    headers: { ...headers },
                    url: `/info`
                };
            }
        }),
        updateLead: builder.mutation<void, { id: string; payload: IContacts }>({
            query: ({ payload, id }) => ({
                method: 'PATCH',
                headers: { ...headers },
                url: `/${id}`,
                body: payload
            })
        }),
        deleteLeads: builder.mutation<void, IDeletePayload>({
            query: (payload: IDeletePayload) => {
                return {
                    method: 'POST',
                    headers: { ...headers },
                    url: `/delete`,
                    body: payload.ids
                };
            }
        })
    })
});

// Exporting hooks generated by the API
export const {
    useLazyGetLeadsAnalyticsQuery,
    useLazyGetLeadsQuery,
    useDeleteLeadsMutation,
    useUpdateLeadMutation
} = adminLeadsApi;
